<div class="body">
    <div class="main">
        <div class="main-text">
            <h3>ERROR CODE: 404</h3>
            <h1>OOOPS!!</h1>
            <h2>The page you are looking for may have been moved, deleted, or possibly never existed.</h2>
            <a href="/">Go Home</a>
        </div>
        <div class="main-image">
            <img src="../../assets/404.png" alt="">
        </div>
    </div>
</div>