import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { ExtraOptions, provideRouter, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { loadingInterceptor } from './loading.interceptor';

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'enabled'
};

export const appConfig: ApplicationConfig = {
  
  providers: [provideRouter(routes, withRouterConfig(routerOptions)), provideAnimationsAsync(), provideHttpClient(withFetch(),withInterceptors([loadingInterceptor]))]
};

