import { Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import { SpiralballComponent } from '../spiralball/spiralball.component';
import { SlideshowComponent } from '../slideshow/slideshow.component';
import { CommonModule } from '@angular/common';
import { TestimonialsComponent } from '../testimonials/testimonials.component';
import { ServicesComponent } from '../services/services.component';
import { LovelyClientComponent } from '../lovely-client/lovely-client.component';
import { ModalContactComponent } from '../modal-contact/modal-contact.component';
import { AppComponent } from '../app.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SpiralballComponent, SlideshowComponent, CommonModule, TestimonialsComponent, ServicesComponent, LovelyClientComponent, ModalContactComponent, LoadingSpinnerComponent ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('2s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ]),
    trigger('fadeInVideo', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.9)' }),
        animate('2000ms ease-in', style({ opacity: 1, transform: 'scale(1)' }))
      ])
    ])
  ]
})
export class HomeComponent {

  constructor(private renderer: Renderer2,private appComponent: AppComponent,private route: ActivatedRoute) { }
  
  @ViewChild('mainVideo') video!: ElementRef;
  @ViewChild('backgroundVideo') videoBck!: ElementRef;
  @ViewChild('zindex') zIndex!: ElementRef;
  public loading: boolean = false;
  private componentsLoaded: { 
    home: boolean;
    services: boolean; 
    slideshow: boolean; 
    testimonials: boolean; 
    spiralball: boolean; 
    lovelyClient: boolean;
  } = {
    home: false,
    services: false,
    slideshow: false,
    testimonials: false,
    spiralball: false,
    lovelyClient: false,
  };


  openModal(): void {
    this.appComponent.openModal();
  }

  // Close modal and restore body scroll
  closeModal(): void {
    this.appComponent.closeModal(false);
  }

  ngOnInit() {

  }

  @HostListener('window:load')
  onWindowLoad() {

    setTimeout( () => {
      this.loading = false;
      this.componentsLoaded['home'] = true;
    },50)
    
  }


  ngAfterViewInit() {
    if(!this.loading) {
        this.video.nativeElement.muted = true;
      if (this.video?.nativeElement?.play) {
        this.video.nativeElement.play();
      }

      this.videoBck.nativeElement.muted = true;
      if (this.video?.nativeElement?.play)  {
        this.videoBck.nativeElement.play();
      }
      this.route.queryParams.subscribe(params => {
        const id = params['scrollTo'];
        if (id) {
          this.scrollToElement('service-section');
        }
      });
      this.checkAllComponentsLoaded();
    }
  }

  scrollToElement(id: string): void {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500); // Adjust the timeout as necessary
    } else {
      console.warn(`Element with ID '${id}' not found`);
    }
  }

  onComponentLoaded(componentName: keyof typeof this.componentsLoaded): void {

    this.componentsLoaded[componentName] = true;
    // this.checkAllComponentsLoaded();
  }

  checkAllComponentsLoaded(): void {
    
    this.loading = !Object.values(this.componentsLoaded).every(loaded => loaded);
    if (
      this.componentsLoaded.lovelyClient == true && 
      this.componentsLoaded.services == true &&
      this.componentsLoaded.slideshow == true && 
      this.componentsLoaded.spiralball == true && 
      this.componentsLoaded.testimonials == true) {
       
        this.loading = false;
    }

  }


}
