<div class="loader-overlay" *ngIf="loading">
  <app-loading-spinner></app-loading-spinner>
</div>

<div [ngStyle]="{ display: loading ? 'none' : 'block' }">
  <div class="z-index3" #zIndex3>
    <div class="z-index main">
      <div class="container" #container>
  
        
        <div class="left-column" #leftColumn>
          <div class="back-button">
            <a (click)="goBack()" class="back-link"> <span class="">&gt;</span> </a>
          </div>
  
          <div class="container-text">
            <div class="title">{{ project?.title }}</div>
            <div class="subtitle">{{ project?.subtitle }}</div>
            <div class="category">
                <div class="cat-category">
                    {{ project?.category }}
                </div>
            </div>
            <div class="description" [innerHTML]="getFormattedDescription()"></div>
  
            <div class="dflex">
                <div class="since-part">
                  <div *ngIf="isVisible; else elseBlock" class="since-part-title">
                    Doing it since
                   </div>
                   <ng-template #elseBlock>
                    <div class="since-part-title" [ngClass]="{ 'smaller-txt': isActive}"> 
                      {{project?.since}}
                    </div>
                  </ng-template>
                
                  <div class="dflex">
                    <div *ngIf="isVisible; else elsetwoBlock" class="txt-and-heart">
                        {{ project?.since}}
                        <img class="heart-c" src="../../assets/heart.svg">
                    </div>
                    <ng-template #elsetwoBlock>
                      <div  class="txt-and-heart" [ngClass]="{ 'smaller-txt-desc': isActive}"> 
                        {{valueTwo}}
                        <img class="heart-c" src="../../assets/heart.svg">
                      </div>
                    </ng-template>
                </div>
                </div>
                <div class="text-and-after-heart">
                    <div class="since-part-title-black">
                        200+ campaigns
                    </div>
                    <div class="txt-and-heart-b">
                        have been created for our <br>clients.
                    </div>
                </div>
            </div>
          </div>
  
        </div>
  
  
        <div class="right-column" #rightColumn>
          <div class="w50">
            <ng-container *ngFor="let media of mediaList">
              <ng-container *ngIf="!isVideo(media)">
                <img [src]="media" [alt]="project?.title" class="project-image" />
              </ng-container>
              <ng-container *ngIf="isVideo(media)">
                <video controls [src]="media.src" class="project-video"></video>
              </ng-container>
            </ng-container>
            <div class="ads-withvideo">
              <div class="lovely-video">
                  <video autoplay muted loop webkit-playsinline playsinline #lovelyVideo id="lovely-video">
                      <source src="../../assets/video/underbackground.mp4" type="video/mp4">
                  </video>
                  <div class="be-on">
                      <div class="kick-a-project"> 
                        How about we team up & make an equally awesome project?
                      </div>
                      <a class="discous-project" (click)="openModal()">
                          DISCUSS YOUR PROJECT
                      </a>
                  </div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>