import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Output, PLATFORM_ID} from '@angular/core';
import { gsap } from "gsap";
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css'
})
export class ServicesComponent {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}
  @Output() loaded = new EventEmitter();

  @HostListener('window:load')
  onWindowLoad() {
    this.loaded.emit('true');
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const dp = window.innerWidth;
      if(dp > 3000 && dp < 4000) {
        this.animationOneXL();
        this.animationTwoXL();
     }if(dp > 2500 && dp < 3000) {
      this.animationOneLL();
      this.animationTwoLL();
    } if(dp > 2000 && dp < 2500) {
      this.animationOneL();
      this.animationTwoL();
    } else if(dp > 1281 && dp < 2000) {
        this.animationOne();
        this.animationTwo();
     } else if (dp > 1025 && dp < 1281) {
        this.animationOne1280();
        this.animationTwo1280();
      } else if (dp > 991 && dp < 1025){
        this.animationOne1025();
        this.animationTwo1025();
    }else if (dp > 480 && dp < 991 ) {
        this.animationOne768();
        this.animationTwo768();
      }else if (dp > 375 && dp < 480 ) {
        this.animationOne479();
        this.animationTwo479();
      } else if (dp <= 375) {
        this.animationOne321();
        this.animationTwo321();
      }
    }

   }
 
   animationOne(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'90em'}, {delay:0.2, duration:1, width:'19em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'40em'}, {width:'19em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'40em'}, {duration:0.5, width:'19em'})
     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.last', {width:'40em'}, {width:'19em'})
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationTwo(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'90em'}, {delay:0.2, duration:1, width:'19em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'40em'}, {width:'19em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'40em'}, {duration:0.5, width:'19em'})
     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.last', {width:'40em'}, {width:'19em'})
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }

   animationOneLL(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'100em'}, {delay:0.2, duration:1, width:'30em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'100em'}, {width:'30em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'100em'}, {duration:0.5, width:'30em'})
     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.last', {width:'100em'}, {width:'30em'})
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationTwoLL(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'100em'}, {delay:0.2, duration:1, width:'30em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'100em'}, {width:'30em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'100em'}, {duration:0.5, width:'30em'})
     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.last', {width:'100em'}, {width:'30em'})
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
    
   animationOneL(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'100em'}, {delay:0.2, duration:1, width:'27em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'100em'}, {width:'26em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'100em'}, {duration:0.5, width:'26em'})
     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.last', {width:'100em'}, {width:'27em'})
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationTwoL(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'100em'}, {delay:0.2, duration:1, width:'27em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'100em'}, {width:'26em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'100em'}, {duration:0.5, width:'26em'})
     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.last', {width:'100em'}, {width:'27em'})
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationOneXL(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'160em'}, {delay:0.2, duration:1, width:'47em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'160em'}, {width:'47em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'160em'}, {duration:0.5, width:'47em'})
     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.last', {width:'160em'}, {width:'49em'})
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationTwoXL(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'160em'}, {delay:0.2, duration:1, width:'47em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'160em'}, {width:'47em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'160em'}, {duration:0.5, width:'47em'})
     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.last', {width:'160em'}, {width:'49em'})
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }

   animationOne1280(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=500%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'65em'}, {delay:0.2, duration:1, width:'18em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.first', {marginLeft:'-19em'}) // Mobile hide

     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'65em'}, {width:'18em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.second', {marginLeft:'-21em'}) // mobile service 2

     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'65em'}, {duration:0.5, width:'18em'})
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.third',{marginLeft:'-20em'})  // mobile service
     
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-item.last', {width:'65em'}, {width:'60em'})
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationTwo1280(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=300%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'65em'}, {delay:0.2, duration:1, width:'18em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.first', {marginLeft:'-19em'}) // Mobile hide

     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'65em'}, {width:'18em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.second', {marginLeft:'-21em'}) // mobile service 2

     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'65em'}, {duration:0.5, width:'18em'})
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.third',{marginLeft:'-20em'})  // mobile service
     
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-item-two.last', {width:'65em'}, {width:'60em'})
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }


   animationOne1025(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=500%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'50em'}, {delay:0.2, duration:1, width:'18em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.first', {marginLeft:'-19em'}) // Mobile hide

     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'50em'}, {width:'18em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.second', {marginLeft:'-21em'}) // mobile service 2

     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'50em'}, {duration:0.5, width:'18em'})
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.third',{marginLeft:'-20em'})  // mobile service
     
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-item.last', {width:'50em'}, {width:'43em'})
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationTwo1025(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=300%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'50em'}, {delay:0.2, duration:1, width:'18em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.first', {marginLeft:'-19em'}) // Mobile hide

     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'50em'}, {width:'18em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.second', {marginLeft:'-21em'}) // mobile service 2

     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'50em'}, {duration:0.5, width:'18em'})
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.third',{marginLeft:'-20em'})  // mobile service
     
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-item-two.last', {width:'50em'}, {width:'43em'})
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }

   animationOne768(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=500%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'40em'}, {delay:0.2, duration:1, width:'18em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.first', {marginLeft:'-19em'}) // Mobile hide

     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'40em'}, {width:'18em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.second', {marginLeft:'-21em'}) // mobile service 2

     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'40em'}, {duration:0.5, width:'18em'})
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.third',{marginLeft:'-20em'})  // mobile service
     
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-item.last', {width:'40em'}, {width:'33em'})
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationTwo768(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=300%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'40em'}, {delay:0.2, duration:1, width:'18em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.first', {marginLeft:'-19em'}) // Mobile hide

     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'40em'}, {width:'18em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.second', {marginLeft:'-21em'}) // mobile service 2

     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'40em'}, {duration:0.5, width:'18em'})
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.third',{marginLeft:'-20em'})  // mobile service
     
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-item-two.last', {width:'40em'}, {width:'33em'})
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }

 
   animationOne479(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=300%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'18em'}, {delay:0.2, duration:1, width:'16em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item.first', {marginLeft:'-19em'}) // Mobile hide

     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'18em'}, {width:'16em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item.second', {marginLeft:'-21em'}) // mobile service 2

     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")

     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'18em'}, {duration:0.5, width:'16em'})
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item.third',{marginLeft:'-20em'})  // mobile service
     
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-item.last', {width:'22em'}, {width:'20em'})
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
   animationTwo479(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=300%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'18em'}, {delay:0.2, duration:1, width:'16em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item-two.first',{marginLeft:'-21em'})
     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'18em'}, {width:'16em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item-two.second', {marginLeft:'-20em'})

     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")

     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'18em'}, {duration:0.5, width:'16em'})
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item-two.third',{marginLeft:'-19em'})
     
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.service-item-two.last', {width:'22em'}, {width:'20em'})
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }

   animationTwo321(): void {
    const container = document.querySelector('.services-wrapper-two');

    // Ensure the element exists before applying animations
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }


     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=600%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item-two.first', {width:'16em'}, {delay:0.2, duration:1, width:'14em',marginLeft:'-17em'})
     .to('.service-item-two.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item-two.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item-two.second', {width:'16em'}, {width:'14em'})
     .to('.service-item-two.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item-two.second', {marginLeft:'-19em'})

     .fromTo('.text-day-wrapper-two.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item-two.third', {width:'16em'}, {duration:0.5, width:'14em'})
     .fromTo('.service-list-text-two.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item-two.third',{marginLeft:'-18em'})
     
     .to('.service-item-two.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper-two.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-item-two.last', {width:'16em'}, {width:'14em'})
     .fromTo('.service-list-text-two.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item-two.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper-two.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text-two.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
   animationOne321(): void {
    const container = document.querySelector('.services-wrapper');
    
    if (!container) {
      console.error('.services-wrapper element not found');
      return;
    }

     const duration = 0.2;
     const servicesAnimation = gsap.timeline({
       scrollTrigger: {
         trigger: container, // Element to trigger animation
         start: 'center center', // When to start the animation
         end: '+=200%', // When to end the animation
         scrub: true, // Smooth animation on scroll
         pin: true, // Pin the trigger element during animation
       },
     });
 
     servicesAnimation
     .fromTo('.service-item.first', {width:'16em'}, {delay:0.2, duration:1, width:'14em'})
     .to('.service-item.first', {color:"#27262b", backgroundColor: "#f5f4f0", delay:0.2, duration:duration}, "<")
     .to('.service-item.first', {marginLeft:'-17em'}) // Mobile hide

     .to('.service-item.second', {color:"#ffffff", backgroundColor: "#EA2E30", delay:0.2, duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .fromTo('.text-day-wrapper.first', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.first', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.service-item.second', {width:'16em'}, {width:'14em'})
     .to('.service-item.second', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item.second', {marginLeft:'-19em'}) // mobile service 2

     .fromTo('.text-day-wrapper.second', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.second', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .to('.service-item.third', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-item.third', {width:'16em'}, {duration:0.5, width:'14em'})
     .fromTo('.service-list-text.third', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.third', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .to('.service-item.third',{marginLeft:'-18em'})  // mobile service
     
     .to('.service-item.last', {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.third', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
     .fromTo('.text-day-wrapper.third', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     
     .fromTo('.service-item.last', {width:'16em'}, {width:'14em'})
     .fromTo('.service-list-text.last', {color:"#939598"}, {color:"#FFFFFF", duration:duration}, "<")
     .to('.service-item.last', {color:"#27262b", backgroundColor: "#f5f4f0", duration:duration, delay:0.5}, "<")
     .fromTo('.text-day-wrapper.last', {color:"#27262b", backgroundColor: "#f5f4f0"}, {color:"#ffffff", backgroundColor: "#EA2E30", duration:duration}, "<")
     .fromTo('.service-list-text.last', {color:"#FFFFFF"}, {color:"#939598", duration:duration}, "<")
   }
 
 

}
