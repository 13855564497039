<div class="testimonials dflex justify-center">
    <div class="w80 dflex mobiledBlock mobilePbTop">
         <div class="testimonials-left w50 pbtop100 mobilepbtop100 animate-left">
             <div class="testimonials-left-title">
                 Kind words from <br>our clients
             </div>
             <div class="testimonials-left-description">
                 We won't just be executors. We'll be your partners, we promise. If you're not convinced, check out our
             verified testimonials from around the world about
             working with us.
             </div>
             <a class="lets-meet-us-testimonials"  (click)="openModal()">
                 Let's Meet
             </a>
         </div>
         <div class="testimonials-right w50 pbtop100 mobilepbtop100 mTop">
             <div class="dblock">
                <div class="testimonials-container">
                    <div 
                      class="testimonial-card" 
                      *ngFor="let testimonial of visibleTestimonials; let i = index"
                      [ngClass]="{ 'active': testimonial.active }"
                    >
                      <div class="testimonial-header">
                        <img [src]="testimonial.avatar" alt="{{ testimonial.name }}" class="avatar" />
                        <div class="testimonial-info">
                          <h3 class="name">{{ testimonial.name }}</h3>
                          <p class="designation">{{ testimonial.designation }}</p>
                        </div>
                      </div>
                      <p class="testimonial-text">{{ testimonial.text }}</p>
                    </div>
                  </div>
                 <div class="navigation-buttons">
                     <button (click)="prevTestimonialSlide()" class="nav-button prev-button">&#8592;</button>
                     <button (click)="nextTestimonialSlide()" class="nav-button next-button">&#8594;</button>
                 </div>
             </div>
         </div>
    </div>
 </div>
 