import { Injectable } from '@angular/core';
import { PROJECTS } from './projects';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  getProjects() {
    return PROJECTS;
  }

  getProjectById(id: number) {
    return PROJECTS.find(project => project.id === id);
  }
}
