<div class="back-white">

  <div #slideshowContainer class="slideshow-container">
    
    <div class="two-parts">
      <div class="superpowers-container">
          
             <!-- Left side: Superpowers list -->
            <div class="superpowers-container">
              <div class="text-superheader">
                What are our <br>superpowers
              </div>
    
              <div
                *ngFor="let power of superpowers; let i = index"
                class="superpower-number"
                #superpower
                [ngClass]="{ 'active': i === currentIndex }"
              >
                {{ power.number }} &NonBreakingSpace; {{ power.text }}
              </div>

            </div>
  
        </div>
  
         <!-- Right side: Dynamic text section -->
          <!-- <div *ngIf="currentIndex < texts.length" class="dynamic-text">
            {{ texts[currentIndex] }}
          </div> -->
          <div class="text-container">
            You know your business better than anyone.<span class="colorblack">And we know about design, PR & brand strategy.</span>
            <div class="text-decoration">
              We won't just be executors.<br>
  We'll be your partners,<br> we promise. If you're not convinced,<br> check out our verified testimonials <br>
  from around the world about <br>working with us.
            </div>
            <a class="lets-meet"  (click)="openModal()">Let’s Meet</a>
          </div>
  
    </div>
  </div>

</div>