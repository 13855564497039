<header class="header"  [ngClass]="{'whitebackground': isScrolled}" [@slideDown]>
    <div class="container">
      <div class="logo">
        <a href="/" class="logoheight">
          <img src="../../assets/logo.svg" alt="Logo" class="logomob">
          <img src="../../assets/logotext.svg" alt="Logo" class="logotext">
        </a>
      </div>
      <nav #menu class="menu">
          <div class="blured-display">
          </div>
            <div class="red-menu">
                <div class="img-container-menu">
                    <img src="../../assets/logoMenu.svg" alt="Logo" class="logomob">
                </div>
                <a class="close-button" (click)="toggleMenu()"> 
                    <div class="aa">

                    </div>
                    <div class="bb">

                    </div>
                </a>
                <div class="menu-position">
                    <ul>
                        <li><a routerLinkActive="active" (click)="routeClick()" routerLink="/" [routerLinkActiveOptions]="{ exact: true }" >Home</a></li>
                        <li><a routerLinkActive="active"  (click)="routeClick()" routerLink="/" [queryParams]="{ scrollTo: 'service' }" >Our Services</a></li>
                        <li><a routerLinkActive="active" (click)="routeClick()" routerLink="/works" >Our Works</a></li>
                        <!-- <li><a routerLinkActive="active" (click)="routeClick()" routerLink="/clients" >Clients</a></li> -->
                        <li><a routerLinkActive="active" (click)="routeClick()" (click)="openModal()" style="cursor: pointer;" >Contact</a></li>
                      </ul>
                </div>
            </div>
        
      </nav>

      <!-- <button class="menu-toggle" (click)="toggleMenu()">☰</button> -->
       <a class="menu-icons" (click)="toggleMenu()">
            <div class="menu-icon-one">

            </div>
            <div class="menu-icon-two">

            </div>
       </a>
    </div>
  </header>
  