import { Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { ProjectService } from '../project.services';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { interval, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-works',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './works.component.html',
  styleUrl: './works.component.css',
  animations: [
    trigger('textAnimation', [
      state('hidden', style({
        opacity: 0,
        transform: 'translateY(20px)'
      })),
      state('visible', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('hidden => visible', [
        animate('1s ease-in-out')
      ]),
    ]),
    trigger('categoryAnimation', [
      state('hidden', style({ opacity: 0 })),
      state('visible', style({ opacity: 1 })),
      transition('hidden => visible', animate('300ms ease-in')),
      transition('visible => hidden', animate('300ms ease-out')),
    ]),
    trigger('projectAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('500ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0, transform: 'scale(0.8)' })),
      ]),
    ]),
  ]
})
export class WorksComponent {
  projects: any = [];
  filteredProjects:any = [];
  categories: string[] = ['All', 'Branding','TVC', 'Social Media','Web', 'Campaigns', 'Event', 'Indoor/Outdoor', 'Print'];
  selectedCategory: string = 'All';
  isMobile:boolean = false;
  isDropdownOpen = false;


  constructor(private projectService: ProjectService, private router: Router,private route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object) {}


  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }
    window.scrollTo(0,0)
    this.projects = this.projectService.getProjects();
    this.filteredProjects = this.projects;

    const dp = window.innerWidth;
    if (dp < 500) {
      this.isMobile = true;
    }

    this.route.queryParams.subscribe(params => {
      let p = params['p'];
      if (p == '' || !p) {
        p = 'All'
      } else if (p == 'Indoor/Outdoor') {
        p = 'Outdoor'
      }
      this.filterProjects(p)
    });

  }

  filterProjects(category: string) {
    const db = window.innerWidth;
    if (db < 500) {
      this.isDropdownOpen = false; 
    }
    this.selectedCategory = category;
    this.filteredProjects = category === 'All' ? this.projects : this.projects.filter((project: any) => project.category === category);
    if (category == 'Indoor/Outdoor') {
      this.filteredProjects = this.projects.filter((project: any) => project.category === 'Outdoor');
    }
  }


  texts = ['exponential', 'strategic', 'engaging', 'outstanding'];
  currentTextIndex = 0;
  state = 'hidden';
  @ViewChild('lovelyVideo') video!: ElementRef;

  ngAfterViewInit() {
    try {
      this.video.nativeElement.muted = true;
      if (this.video?.nativeElement?.play) {
        this.video.nativeElement.play();
      }
    } catch (error) {
      console.error('Error during video playback:', error);
    }

    //this.animateText();
  }

  intervalId: any;

  animateText() {
    interval(3000)
      .pipe(
        switchMap(() => {
          this.state = 'hidden';
          return timer(500);
        })
      )
      .subscribe(() => {
        this.currentTextIndex = (this.currentTextIndex + 1) % this.texts.length;
        this.state = 'visible';
      });
  }
  
  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  navigateToProject(projectId: string): void {
    this.router.navigate(['/project/', projectId], { queryParams: { p: 0 } });
  }


  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

}
