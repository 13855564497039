import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
  animations: [

    trigger('slideDown', [
     transition(':enter', [
       style({ transform: 'translateY(-100%)' }),
       animate('500ms ease-in', style({ transform: 'translateY(0%)' }))
     ]),
     transition(':leave', [
       animate('500ms ease-out', style({ transform: 'translateY(-100%)' }))
     ])
   ]),

],
})
export class HeaderComponent {
    title = 'Advors Agency';
    menuVisible = false;
    isScrolled = false;

    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      const menu = document.querySelector('.menu') as HTMLElement;
      if (this.menuVisible) {
        menu.classList.add('show');
      } else {
        menu.classList.remove('show');
      }
    }

    constructor(private appComponent: AppComponent) {

    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
      const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.isScrolled = scrollOffset > 500;
    }

  routeClick(){
    const menu = document.querySelector('.menu') as HTMLElement;
    menu.classList.remove('show');
  }
  
 openModal(): void {
    this.appComponent.openModal();
  }

  // Close modal and restore body scroll
  closeModal(): void {
    this.appComponent.closeModal(false);
  }
  
}
