import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slideshow',
  standalone: true,
  imports: [CommonModule],
  providers:[],
  templateUrl: './slideshow.component.html',
  styleUrl: './slideshow.component.css'
})
export class SlideshowComponent {
  activeIndex = 0;
  autoplayInterval: any;
  @ViewChild('sliderContainer', { static: false }) sliderContainer!: ElementRef;
  @ViewChild('ourProject', { static: true }) ourProject!: ElementRef;
  
  constructor(private router: Router,@Inject(PLATFORM_ID) private platformId: Object) {}

  @Output() loaded = new EventEmitter();

  @HostListener('window:load')
  onWindowLoad() {
    this.loaded.emit('true');
  }

  slides = [
    { title: 'Print', image: 'assets/pr-print.png' },
    { title: 'Campaigns', image: 'assets/pr-campaign.png' },
    { title: 'Web', image: 'assets/pr-web.png' },
    { title: 'Social Media', image: 'assets/pr-socialmedia.png' },
    { title: 'Branding', image: 'assets/pr-branding.png' },
    { title: 'TV Commercial Animations', image: 'assets/pr-tv.png' },
    { title: 'Indoor/Outdoor', image: 'assets/pr-indoor.png' },
  ];

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    
    if (isPlatformBrowser(this.platformId)) {
      const dp = window.innerWidth;
      if (dp > 800) {
        this.setupHammerEvents();
      }
    }

    
   }

  setupHammerEvents() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.ourProject.nativeElement.classList.add('animate');
            observer.unobserve(this.ourProject.nativeElement); // Stop observing after animation
          }
        });
      },
      { threshold: 0.2 } // Trigger when 20% of the element is visible
    );

    observer.observe(this.ourProject.nativeElement);
  }
  
  onSwipe(event: any) {
    if (event.deltaX > 0) {
      this.stopAutoplay();
        this.nextSlide();
        this.startAutoplay(); // Restart autoplay after interaction
    } else {
      this.stopAutoplay();
        this.prevSlide();
        this.startAutoplay(); // Restart autoplay after interaction
    }
  }

  ngOnDestroy() {
    this.stopAutoplay();

  }

  startAutoplay() {
    this.autoplayInterval = setInterval(() => {
      this.nextSlide();
    }, 3000); // Change slide every 3 seconds

  }

  stopAutoplay() {
    if (this.autoplayInterval) {
      clearInterval(this.autoplayInterval);
    }

  }

  onUserInteraction() {
    this.stopAutoplay();
  }


  nextSlide() {
    // Wrap around to the first slide when at the last slide
    this.activeIndex = (this.activeIndex + 1) % this.slides.length;
  }

  prevSlide() {
    // Wrap around to the last slide when at the first slide
    this.activeIndex = (this.activeIndex - 1 + this.slides.length) % this.slides.length;
  }

  @HostListener('swipeleft', ['$event'])
  onSwipeLeft(event: Event) {
    this.stopAutoplay();
    this.nextSlide();

  }

  @HostListener('swiperight', ['$event'])
  onSwipeRight(event: Event) {
    this.stopAutoplay();
    this.prevSlide();
  }

  onSlideClick(data: string): void {
    this.router.navigate(['works'], { queryParams: { p: data } });
  }
  allprojects() {
    this.router.navigate(['works']);
  }

  scrollAmount = 100;

  @HostListener('swipeup', ['$event'])
  onSwipeUp(event: any) {
    // Optionally handle vertical swipe or ignore it
    this.stopAutoplay();
    console.log('swipeUp-',event)
    this.scrollPage('up');
  }

  @HostListener('swipedown', ['$event'])
  onSwipeDown(event: any) {
    this.stopAutoplay();
    // Optionally handle vertical swipe or ignore it
    this.scrollPage('down');
  }

  scrollPage(direction: 'up' | 'down') {
    const scrollDistance = direction === 'up' ? this.scrollAmount : -this.scrollAmount;
    window.scrollBy({
      top: scrollDistance,
      behavior: 'smooth'
    });
  }

}
