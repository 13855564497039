<div class="z-index3" #zIndex3>
    <div class="z-index main">
      <div class="container" #container>

        
        <div class="left-column" #leftColumn>
          <div class="back-button">
            <a (click)="goBack()" class="back-link"> <span class="">&gt;</span> </a>
          </div>
  
          <div class="container-text">
            <div class="title">{{ project?.title }}</div>
            <div class="subtitle">{{ project?.subtitle }}</div>
            <div class="category">
                <div class="cat-category">
                    {{ project?.category }}
                </div>
            </div>
            <div class="description" [innerHTML]="getFormattedDescription()"></div>

            <div class="dflex">
                <div class="since-part">
                    <div class="since-part-title">
                        Doing it since
                    </div>
                    <div class="dflex">
                        <div class="txt-and-heart">
                            {{ project?.since}}
                            <img class="heart-c" src="../../assets/heart.svg">
                        </div>
                    </div>
                </div>
                <div class="text-and-after-heart">
                    <div class="since-part-title-black">
                        200+ campaigns
                    </div>
                    <div class="txt-and-heart-b">
                        have been created for our <br>clients.
                    </div>
                </div>
            </div>
          </div>

        </div>
  

        <div class="right-column" #rightColumn>
          <div class="w50">
            <ng-container *ngFor="let media of mediaList">
              <ng-container *ngIf="!isVideo(media)">
                <img [src]="media" [alt]="project?.title" class="project-image" />
              </ng-container>
              <ng-container *ngIf="isVideo(media)">
                <video controls [src]="media.src" class="project-video"></video>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  