import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProjectService } from '../project.services';
import { CommonModule, isPlatformBrowser, Location } from '@angular/common';
import { gsap, ScrollTrigger } from 'gsap/all';
import { LoadingService } from '../loading.service';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { AppComponent } from '../app.component';

gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-projectsv2',
  standalone: true,
  imports: [CommonModule, LoadingSpinnerComponent],
  templateUrl: './projectsv2.component.html',
  styleUrl: './projectsv2.component.css'
})
export class Projectsv2Component {

  @ViewChild('rightColumn') rightColumn!: ElementRef;
  @ViewChild('zIndex3') zIndex3!: ElementRef;
  @ViewChild('leftColumn') leftColumn!: ElementRef;
  @ViewChild('container') container!: ElementRef;
  projectId: string | null = null;
  project: any = null;
  mediaList: (string | { type: 'video'; src: string })[] = [];
  private animation!: GSAPTimeline; 
  public loading: boolean = true;
  isVisible:boolean = true;
  valueTwo: string = '';
  isActive:boolean = false;
  

  constructor(private route: ActivatedRoute,private appComponent: AppComponent, private projectService: ProjectService, @Inject(PLATFORM_ID) private platformId: Object, private location: Location,private router: Router, private cd: ChangeDetectorRef) {

  }


  openModal(): void {
    this.appComponent.openModal();
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return
    }
    
    window.scrollTo(0,0);
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.route.queryParams.subscribe(params => {
      const p = params['p'];
      if (p == 0) {
        this.router.navigateByUrl(`/project/${this.projectId}?p=1`).then(() => {
          window.location.reload();
        });
      }
    });
    
    if (this.projectId !== null) {
      const num = parseInt(this.projectId, 10);
      if (!isNaN(num)) {
        this.project = this.projectService.getProjectById(num);
        const { images, videos } = this.project!;

        if (this.project.category === 'Branding' || this.project.category === 'Event' ||(this.project.category === 'Outdoor' && this.project.since != 'Over 3000+')) {
          this.isVisible = false;
          this.valueTwo = this.project.since
          this.project.since = 'Client Since'
        }

        if (this.project.since === 'Over 3000+') {
          this.isVisible = false;
          this.valueTwo = 'Prints'
        }


        if (this.project.since === '200% increased') {
          this.isVisible = false;
          this.valueTwo = 'sales'
        }

        if (this.project.since === '40+ Tv Commercials') {
          this.isVisible = false;
          this.isActive = true;
          this.valueTwo = 'have been produced for our clients.';
        }

        const maxLength = Math.max(images.length, videos.length);
        for (let i = 0; i < maxLength; i++) {
          if (i < images.length) {
            if (i == 0) {
              continue
            }
            this.mediaList.push(images[i]); // Add image
          }
          if (i < videos.length) {
            this.mediaList.push({ type: 'video', src: videos[i] }); // Add video
          }
        }
      }
    } else {

    }
  }


  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      
    }
  }

  @HostListener('window:load')
  onWindowLoad() {
   
    const dp = window.innerWidth;
    if (dp > 500) {
      this.initScrollTrigger();
    }
    setTimeout( () => {
      this.loading = false; 
    },50)
    
  }


  initScrollTrigger(): void {
    const leftColumn = this.leftColumn.nativeElement;
    const rightColumn = this.rightColumn.nativeElement;
  
    // Ensure the DOM is fully rendered
    setTimeout(() => {
      const leftRect = leftColumn.getBoundingClientRect();
      const rightRect = rightColumn.getBoundingClientRect();
      let hg = 0;
      let start = 'top top';
      let gg = window.innerWidth;
      if (gg >  2500) {
        hg = -90;
        let t = leftRect.top
        start ='top ' + t;
      }else if (gg < 2400 && gg > 1880) {
        hg = -90;
        let t = leftRect.top
        start ='top ' + t;
      }else if (gg < 1880 && gg > 1480 ) {
        hg = 0;
        let t = leftRect.top
        start ='top ' + t;
      }else if (gg < 1480 ) {
        hg = 0;
        let t = leftRect.topc
        start ='top ' + t;
      }
  
      const leftHeight = leftRect.height;
      const rightHeight = rightRect.height;
  
  
      const scrollEndPosition = rightHeight - leftHeight;
  
      if (scrollEndPosition > 0) {
        this.animation = gsap.timeline({
          scrollTrigger: {
            trigger: rightColumn,
            start: start,
            end: `+=${scrollEndPosition}`,
            pin: leftColumn,
            scrub: true,
            onUpdate: (self) => {
            },
            // markers: true, // Optional: for debugging start/end points
          },
        });
  
        this.animation.to(rightColumn, {
          opacity: 1,
          duration: 1,
        });
  
        // Refresh ScrollTrigger to update dimensions
        ScrollTrigger.refresh();
      } else {
        console.warn('Left column is taller than or equal to right column!');
      }
  
      this.cd.detectChanges(); // Ensure Angular reflects changes
    }, 100); // Timeout ensures layout is stable
  }
  
  

  goBack(): void {
    this.router.navigate(['works']);
  }

  getFormattedDescription() {
    if(this.project) {
      return this.project.description.replace(/\n/g, '<br> <br> <br>');
    }
   
  }

  isVideo(media: string | { type: 'video'; src: string }): media is { type: 'video'; src: string } {
    return typeof media === 'object' && media.type === 'video';
  }

}
