// src/app/global-variables.ts
export const PROJECTS = [
    {
      id: 1,
      name: 'INVEST',
      category: 'Branding',
      images: ['assets/projects/invest/1.png',
        'assets/projects/invest/2.png',
        'assets/projects/invest/3.png',
        'assets/projects/invest/4.png',
        'assets/projects/invest/5.png',
        'assets/projects/invest/6.png',
        'assets/projects/invest/7.png',
        'assets/projects/invest/8.png'],
      videos: [],
      title :'LB Invest',
      subtitle: 'LB Invest, Investing Co',
      since:'2018',
      description:'LB Invest from Macedonia entrusted us with the responsibility of creating their new identity, giving us the opportunity to shape a brand that reflects their strength and vision in the construction industry.\nOur process for brand and logo creation involved thorough research on the industry and the company vision. Initially, we developed the main concepts, based on their identity and values. \nAfter continuous consultations and adjustments, we created a logo that reflects the company strong and stable identity, strengthening their position in the market.',
    },
    {
      id: 2,
      name: 'MIMOZA®',
      category: 'Branding',
      images: ['assets/projects/mimoza/1.png',
                'assets/projects/mimoza/2.png',
                'assets/projects/mimoza/3.png',
                'assets/projects/mimoza/4.png',
                'assets/projects/mimoza/5.png',
                'assets/projects/mimoza/6.png',
                'assets/projects/mimoza/7.png',
                'assets/projects/mimoza/8.png'],
      videos: [],
      title :'Mimoza',
      subtitle: 'Sweden, Beauty Academy',
      since:'2018',
      description:'A facial treatment expert from Sweden, entrusted us with the creation and development of her brand, crafting an identity that transcends boundaries and reflects the high quality of her services.\nOur workflow included designing catalogs and other promotional materials, with a special focus on details.\nWe worked closely with Mimoza to ensure that every element conveys her professionalism and dedication, creating a coherent and attractive image for her clients.',
    },
    {
      id: 3,
      name: 'LINDA',
      category: 'Branding',
      images: ['assets/projects/linda/1.png',
                'assets/projects/linda/2.png',
                'assets/projects/linda/3.png',
                'assets/projects/linda/4.png',
                'assets/projects/linda/5.png',
                'assets/projects/linda/6.png',
                'assets/projects/linda/7.png',
                'assets/projects/linda/8.png'],
      videos: [],
      title:'Linda',
      subtitle:'Prishtina, Brick Manufacturing',
      since:'2018',
      description:'A company specialized in brick manufacturing and pool construction, trusted us with the development of its brand.\nCombining tradition and innovation, we recreated an identity that reflects the quality and durability of their products in the market.\nIn our workflow, we developed a customized strategy for the redesign of the logo and other promotional materials, focusing on details to ensure that the brand is in full harmony with the company vision and values.',
    },
    {
      id: 4,
      name: 'INFOBOX',
      category: 'Branding',
      images: ['assets/projects/infobox/1.png',
        'assets/projects/infobox/2.png',
        'assets/projects/infobox/3.png',
        'assets/projects/infobox/4.png',
        'assets/projects/infobox/5.png',
        'assets/projects/infobox/6.png',
        'assets/projects/infobox/7.png',
        'assets/projects/infobox/8.png'],
      videos: [],
      title:'InfoBox',
      subtitle:'Prishtina, TV Show',
      since:'2018',
      description:'An innovative television show, entrusted us with the creation of its brand.\nOur goal was to develop a visual identity that reflected the dynamism of the programs information, attracting and engaging the audience in every episode.\nDuring our work process, we created a logo and tailored graphics for the screen, ensuring that every visual element harmonizes with the actual nature of the show.',
    },
    {
      id: 5,
      name: 'EXTRA FRUIT',
      category: 'Branding',
      images: ['assets/projects/extrafruite/1.png',
        'assets/projects/extrafruite/2.png',
        'assets/projects/extrafruite/3.png',
        'assets/projects/extrafruite/4.png',
        'assets/projects/extrafruite/5.png',
        'assets/projects/extrafruite/6.png',
        'assets/projects/extrafruite/7.png',
        'assets/projects/extrafruite/8.png'
      ],
      videos: [],
      title:'Extra Fruit',
      subtitle:'Prishtina, Fresh Drink',
      since:'2018',
      description:'A renowned company for producing natural juices, trusted us with the development of its brand.\nWe created a fresh and attractive identity that reflects the quality and naturalness of their products, strengthening the brand\'s position in the market.\nIn our work process, we developed appealing designs for the packaging and promotional materials, ensuring that every element reflects the freshness and naturalness of Extra Fruit\'s juices.',
    },
    {
      id: 6,
      name: 'DEA',
      category: 'Branding',
      images: ['assets/projects/dea/1.png',
        'assets/projects/dea/2.png',
        'assets/projects/dea/3.png',
        'assets/projects/dea/4.png',
        'assets/projects/dea/5.png',
        'assets/projects/dea/6.png',
        'assets/projects/dea/7.png',
        'assets/projects/dea/8.png'
      ],
      videos: [],
      title:'Dea',
      subtitle:'Gjilan, Water',
      since:'200% increased',
      description:'A major water production company, entrusted us with the rebranding of its identity.\nThanks to this rebranding, the client has achieved an extraordinary market presence increase of over 200 percent.\nIn our workflow, we created a modern and attractive design for packaging and promotional materials, improving the brand\'s image and visibility.\nThis has helped strengthen Dea\'s position in a competitive market.',
    },
    {
      id: 7,
      name: 'DEGEZA',
      category: 'Branding',
      images: ['assets/projects/degeza/1.png',
        'assets/projects/degeza/2.png',
        'assets/projects/degeza/3.png',
        'assets/projects/degeza/4.png',
        'assets/projects/degeza/5.png',
        'assets/projects/degeza/6.png',
        'assets/projects/degeza/7.png',
        'assets/projects/degeza/8.png'
      ],
      videos: [],
      title:'Degeza',
      subtitle:'Prishtina, kindergarten',
      since:'2023',
      description:'Entrusted us with developing its brand, including the creation of the brand name and promotional materials.\nWe created a warm and friendly identity that reflects the kindergarten\'s commitment to education and development of children.\nDuring the work process, we designed every element of the brand, from the logo to other materials, focusing on creating an attractive image suitable for the educational environment.',
    },
    {
      id: 8,
      name: 'MAZREKU',
      category: 'Branding',
      images: ['assets/projects/mazreku/1.png',
        'assets/projects/mazreku/2.png',
        'assets/projects/mazreku/3.png',
        'assets/projects/mazreku/4.png',
        'assets/projects/mazreku/5.png',
        'assets/projects/mazreku/6.png',
        'assets/projects/mazreku/7.png',
        'assets/projects/mazreku/8.png'
      ],
      videos: [],
      title:'Mazreku',
      subtitle:'Prishtina, TV Show',
      since:'2023',
      description:'A German investor in Kosovo, entrusted us with developing the brand for his network of stores offering doors, windows, and other materials.\nWe created a strong identity that reflects his quality and professionalism in this sector.\nDuring the work process, we created a coherent design for the logo and promotional materials, ensuring a strong and appealing presence in the Kosovar market.',
    },
    {
      id: 9,
      name: 'CLEAN & SHINE',
      category: 'Branding',
      images: ['assets/projects/cleanshine/1.png',
        'assets/projects/cleanshine/2.png',
        'assets/projects/cleanshine/3.png',
        'assets/projects/cleanshine/4.png',
        'assets/projects/cleanshine/5.png',
        'assets/projects/cleanshine/6.png',
        'assets/projects/cleanshine/7.png',
        'assets/projects/cleanshine/8.png'
      ],
      videos: [],
      title:'Clean and Shine',
      subtitle:'Germany, cleaning Services',
      since:'2018',
      description:'Clean and Shine, a German cleaning company, entrusted us with the development of its brand.\nWe created an attractive identity that reflects the professionalism and quality of its services.\nIn the work process, we developed a clear and modern design for the logo and promotional materials, ensuring that every element represents the company\'s reliability and efficiency.',
    },
    {
      id: 10,
      name: 'TRIO TRAVEL',
      category: 'Branding',
      images: ['assets/projects/trio/1.png',
        'assets/projects/trio/2.png',
        'assets/projects/trio/3.png',
        'assets/projects/trio/4.png',
        'assets/projects/trio/5.png',
        'assets/projects/trio/6.png',
        'assets/projects/trio/7.png',
        'assets/projects/trio/8.png'
      ],
      videos: [],
      title:'TrioTravel',
      subtitle:'Sweeden, Travel Agency',
      since:'2018',
      description:'A Swedish travel agency, entrusted us with developing its brand.\nWe created a fresh and exciting identity that perfectly connects the sense of adventure with its personalized services.\nDuring the work process, we developed a logo and promotional materials that reflect the feeling of exploration and excitement, creating an appealing image to attract travelers.',
    },
    {
      id: 11,
      name: 'MSB ELEKTRONIK',
      category: 'Branding',
      images: ['assets/projects/msb/1.png',
        'assets/projects/msb/2.png',
        'assets/projects/msb/3.png',
        'assets/projects/msb/4.png',
        'assets/projects/msb/5.png',
        'assets/projects/msb/6.png',
        'assets/projects/msb/7.png',
        'assets/projects/msb/8.png',
        'assets/projects/msb/9.png',
        'assets/projects/msb/10.png',
        'assets/projects/msb/11.png'

      ],
      videos: [],
      title:'MSB Elektronik',
      subtitle:'Germany, Electronics Installments',
      since:'2023',
      description:'A German electrical installation company, entrusted us with creating its brand.\nWe developed a strong and reliable identity that reflects their professionalism and expertise in the electrical field.\nDuring the work process, we designed a logo and promotional materials that embody the quality and dedication of MSB to their services, helping to strengthen the brand\'s presence in the market.',
    },
    {
      id: 12,
      name: 'PANNA',
      category: 'Branding',
      images: ['assets/projects/panna/1.png',
        'assets/projects/panna/2.png',
        'assets/projects/panna/3.png',
        'assets/projects/panna/4.png',
        'assets/projects/panna/5.png',
        'assets/projects/panna/6.png',
        'assets/projects/panna/7.png',
        'assets/projects/panna/8.png',
        'assets/projects/panna/9.png',
        'assets/projects/panna/10.png',
        'assets/projects/panna/11.png',

      ],
      videos: [],
      title:'PANNA',
      subtitle:'Switzerland, Architect studio',
      since:'2018',
      description:'Panna, a company specialized in architectural constructions in Switzerland, entrusted us with creating its brand.\nWe developed a sophisticated identity that reflects the elegance and innovation in their construction projects. \nDuring the work process, we created a logo and promotional materials that reflect the high level of professionalism and creativity of Panna, strengthening the company\'s image in the Swiss market.',
    },
    {
      id: 13,
      name: 'GO',
      category: 'Branding',
      images: ['assets/projects/go/1.png',
        'assets/projects/go/2.png',
        'assets/projects/go/3.png',
        'assets/projects/go/4.png',
        'assets/projects/go/5.png',
        'assets/projects/go/6.png',
        'assets/projects/go/7.png',
        'assets/projects/go/8.png',
        'assets/projects/go/9.png',
        'assets/projects/go/10.png',
        'assets/projects/go/11.png'
      ],
      videos: [],
      title:'GO Condoms',
      subtitle:'Prishtina, TV Show',
      since:'2024',
      description:'Go, a new condom manufacturing factory, entrusted us with creating its brand and name.\nWe developed an innovative and attractive identity that reflects the quality and standard of their product.\nIn the work process, we designed the logo and promotional materials, ensuring that each element represents the reliability and unique style of Go condoms.',
    },
    {
      id: 14,
      name: 'DONNYBROOK',
      category: 'Branding',
      images: ['assets/projects/donnybrook/1.png',
        'assets/projects/donnybrook/2.png',
        'assets/projects/donnybrook/3.png',
        'assets/projects/donnybrook/4.png',
        'assets/projects/donnybrook/5.png',
        'assets/projects/donnybrook/6.png',
        'assets/projects/donnybrook/7.png',
        'assets/projects/donnybrook/8.png',
        'assets/projects/donnybrook/9.png',
        'assets/projects/donnybrook/10.png',
        'assets/projects/donnybrook/11.png'
      ],
      videos: [],
      title:'Donny Brook',
      subtitle:'London, Bike Shop',
      since:'2018',
      description:'A British company specialized in buying and selling bicycles, entrusted us with creating its brand.We developed an identity that reflects the dynamism and enthusiasm for cycling, making the brand distinctive and appealing in the market.During the work process, we created a logo and promotional materials that reflect the energy and professionalism of Donny Book\'s services, contributing to the strengthening of the brand\'s presence in the bicycle sector.',
    },
    {
      id: 15,
      name: 'FLUIDI-M',
      category: 'Branding',
      images: ['assets/projects/fluidimkd/1.png',
        'assets/projects/fluidimkd/2.png',
        'assets/projects/fluidimkd/3.png',
        'assets/projects/fluidimkd/4.png',
        'assets/projects/fluidimkd/5.png',
        'assets/projects/fluidimkd/6.png',
        'assets/projects/fluidimkd/7.png',
        'assets/projects/fluidimkd/8.png',
        'assets/projects/fluidimkd/9.png',
        'assets/projects/fluidimkd/10.png'

      ],
      videos: [],
      title:'Fluidi Macedonia',
      subtitle:'Macedonia, drink production',
      since:'2018',
      description:'One of the largest distributors in Macedonia, entrusted us with developing its brand.\nWe created a strong and professional identity that reflects their commitment to offering high-level products and services.\nDuring the work process, we designed a logo and promotional materials that reflect the reliability and expertise of Fluidi, helping to strengthen the brand\'s presence in the Macedonian market.',
    },
    {
      id: 16,
      name: 'GET ACCESSORIES',
      category: 'Branding',
      images: ['assets/projects/access/1.png',
        'assets/projects/access/2.png',
        'assets/projects/access/3.png',
        'assets/projects/access/4.png',
        'assets/projects/access/5.png',
        'assets/projects/access/6.png',
        'assets/projects/access/7.png',
        'assets/projects/access/8.png',
        'assets/projects/access/9.png',
        'assets/projects/access/10.png',
        'assets/projects/access/11.png'
      ],
      videos: [],
      title:'Get Accessories',
      subtitle:'Prishtina, Accessories',
      since:'2018',
      description:'A renowned brand known for its stylish and quality accessories, entrusted us with developing its brand.We created an identity that reflects the elegance and innovation in their products.Throughout the work process, we developed a logo and promotional materials that embody the style and professionalism of Geet Accessories, helping to increase brand recognition and attract customers in the market.',
    },
    {
      id: 17,
      name: 'AMBERO',
      category: 'Branding',
      images: ['assets/projects/ambero/1.png',
        'assets/projects/ambero/2.png',
        'assets/projects/ambero/3.png',
        'assets/projects/ambero/4.png',
        'assets/projects/ambero/5.png',
        'assets/projects/ambero/6.png',
        'assets/projects/ambero/7.png',
        'assets/projects/ambero/8.png',
        'assets/projects/ambero/9.png',
        'assets/projects/ambero/10.png',
        'assets/projects/ambero/11.png'
      ],
      videos: [],
      title:'Ambero',
      subtitle:'Prishtina, Furniture Factory',
      since:'2018',
      description:'A leader in constructing kitchens, bedrooms, and other spaces, entrusted us with creating its brand.We developed an identity that reflects the high quality and sophistication of their construction services.During the work process, we created a logo and promotional materials that represent the high level of professionalism and refined details of Ambero, contributing to strengthening the brand image and attracting customers in this sector.',
    },
    {
      id: 18,
      name: 'Tv Ads & Animation',
      category: 'TVC',
      images: ['assets/projects/unk/1.png'],
      videos: ['assets/video/projects/paguaj-online.mp4',
        'assets/video/projects/tv2.mp4',
        'assets/video/projects/blejonlinefluidi.mp4',
        'assets/video/projects/telkostv.mp4',
        'assets/video/projects/telkos2.mp4',
        'assets/video/projects/floil.mp4',
        'assets/video/projects/fundviti1.mp4',
        'assets/video/projects/fundviti2.mp4',
        'assets/video/projects/LIRI.mp4',
        'assets/video/projects/pergadit-me-zemer.mp4',
        'assets/video/projects/jaffa3d.mp4',
        'assets/video/projects/jaffachamp.mp4',
        'assets/video/projects/jaffachamp2.mp4',
        'assets/video/projects/rccola.mp4',
        'assets/video/projects/redrain.mp4',
        'assets/video/projects/babadimri.mp4',
      ],
      title:'Tv Ads & Animation',
      subtitle:'',
      since:'40+ Tv Commercials',
      description:'We have successfully created a series of television ads that meet the highest standards of the industry.\nOur work reflects creativity and professionalism in every detail. Below you will find some of our most notable achievements.',
    },
    {
      id: 19,
      name: 'Tv Ads & Animation',
      category: 'Campaigns',
      images: ['assets/projects/unk1/1.png'],
      videos: ['assets/video/projects/paguaj-online.mp4',
        'assets/video/projects/tv2.mp4',
        'assets/video/projects/blejonlinefluidi.mp4',
        'assets/video/projects/telkostv.mp4',
        'assets/video/projects/telkos2.mp4',
        'assets/video/projects/floil.mp4',
        'assets/video/projects/fundviti1.mp4',
        'assets/video/projects/fundviti2.mp4',
        'assets/video/projects/LIRI.mp4',
        'assets/video/projects/pergadit-me-zemer.mp4',
        'assets/video/projects/jaffa3d.mp4',
        'assets/video/projects/jaffachamp.mp4',
        'assets/video/projects/jaffachamp2.mp4',
        'assets/video/projects/rccola.mp4',
        'assets/video/projects/redrain.mp4',
        'assets/video/projects/babadimri.mp4',
      ],
      title:'Tv Ads & Animation',
      subtitle:'',
      since:'40+ Tv Commercials',
      description:'We have successfully created a series of television ads that meet the highest standards of the industry.\nOur work reflects creativity and professionalism in every detail. Below you will find some of our most notable achievements.',
    },
    {
      id: 20,
      name: 'Shtande',
      category: 'Outdoor',
      images: ['assets/projects/indor1/1.png',
        'assets/projects/indor1/2.png',
        'assets/projects/indor1/3.png',
        'assets/projects/indor1/4.png',
        'assets/projects/indor1/5.png',
        'assets/projects/indor1/6.png'
      ],
      videos: [],
      title:'Shtande',
      subtitle:'Kosovo, Fluidi Corporation',
      since:'2018',
      description:'We have created and implemented indoor materials for a series of successful projects, transforming spaces into impressive visual experiences.Our projects reflect style, creativity, and functionality. Below are some examples of our work.',
    },
    {
      id: 21,
      name: 'Shtande',
      category: 'Outdoor',
      images: ['assets/projects/indor2/1.png',
        'assets/projects/indor2/2.png',
        'assets/projects/indor2/3.png',
        'assets/projects/indor2/4.png',
        'assets/projects/indor2/5.png',
        'assets/projects/indor2/6.png',
        'assets/projects/indor2/7.png',
        'assets/projects/indor2/8.png'
      ],
      videos: [],
      title:'Fridge Print',
      subtitle:'Kosovo, Fluidi Corporation',
      since:'2018',
      description:'We have created and implemented indoor materials for a series of successful projects, transforming spaces into impressive visual experiences.\nOur projects reflect style, creativity, and functionality. Below are some examples of our work.',
    },
    {
      id: 22,
      name: 'Shtande',
      category: 'Outdoor',
      images: ['assets/projects/indor3/1.png',
        'assets/projects/indor3/2.png',
        'assets/projects/indor3/3.png',
        'assets/projects/indor3/4.png',
        'assets/projects/indor3/5.png',
        'assets/projects/indor3/6.png'
      ],
      videos: [],
      title:'Shtande',
      subtitle:'Kosovo, Fluidi Corporation',
      since:'2018',
      description:'We have created and implemented indoor materials for a series of successful projects, transforming spaces into impressive visual experiences.\nOur projects reflect style, creativity, and functionality. Below are some examples of our work.',
    },
    {
      id: 23,
      name: 'SHTEPIA E BABADIMRIT',
      category: 'Campaigns',
      images: ['assets/projects/shtepiababadimrit/1.png',
        'assets/projects/shtepiababadimrit/2.png',
        'assets/projects/shtepiababadimrit/3.png',
        'assets/projects/shtepiababadimrit/4.png',
        'assets/projects/shtepiababadimrit/5.png',
        'assets/projects/shtepiababadimrit/6.png',
        'assets/projects/shtepiababadimrit/7.png',
        'assets/projects/shtepiababadimrit/8.png'
      ],
      videos: ['assets/video/projects/babadimri.mp4'],
      title:'Shtëpia e Babadimrit',
      subtitle:'Prishtina, Kosova',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.\nWith a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.\nExplore some of our most successful projects for various clients below.',
    },
    {
      id: 24,
      name: 'CAPPADOCIA',
      category: 'Campaigns',
      images: ['assets/projects/cappadocia/1.png',
        'assets/projects/cappadocia/2.png',
        'assets/projects/cappadocia/3.png',
        'assets/projects/cappadocia/4.png',
        'assets/projects/cappadocia/5.png',
        'assets/projects/cappadocia/6.png',
        'assets/projects/cappadocia/7.png',
        'assets/projects/cappadocia/8.png',
        'assets/projects/cappadocia/9.png',
        'assets/projects/cappadocia/10.png',
        'assets/projects/cappadocia/11.png'
      ],
      videos: [],
      title:'Kapak për Cappadocia',
      subtitle:'Jaffa Champion',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.\nWith a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.\nExplore some of our most successful projects for various clients below.',
    },
    {
      id: 25,
      name: 'Social Media',
      category: 'Campaigns',
      images: ['assets/projects/socialmedia/1.png',
          'assets/projects/socialmedia/2.png',
          'assets/projects/socialmedia/3.png',
          'assets/projects/socialmedia/4.png',
          'assets/projects/socialmedia/5.png',
          'assets/projects/socialmedia/6.png',
          'assets/projects/socialmedia/7.png',
          'assets/projects/socialmedia/8.png',
          'assets/projects/socialmedia/9.png',
          'assets/projects/socialmedia/10.png',
          'assets/projects/socialmedia/11.png',
          'assets/projects/socialmedia/12.png',
          'assets/projects/socialmedia/13.png',
          'assets/projects/socialmedia/14.png'
      ],
      videos: [],
      title:'Pije me dashni',
      subtitle:'Jaffa Champion',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.With a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.Explore some of our most successful projects for various clients below.',
    },
    {
      id: 26,
      name: 'MERRJA VERES FRESKINE',
      category: 'Campaigns',
      images: ['assets/projects/veres/1.png',
        'assets/projects/veres/2.png',
        'assets/projects/veres/3.png',
        'assets/projects/veres/4.png',
        'assets/projects/veres/5.png',
        'assets/projects/veres/6.png',
        'assets/projects/veres/7.png',
        'assets/projects/veres/8.png',
        'assets/projects/veres/9.png',
        'assets/projects/veres/10.png',
        'assets/projects/veres/11.png'
      ],
      videos: [],
      title:'Merrja verës freskinë',
      subtitle:'Jaffa Champion',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.\nWith a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.\nExplore some of our most successful projects for various clients below.',
    },
    {
      id: 27,
      name: 'Përgadit',
      category: 'Campaigns',
      images: ['assets/projects/pergadit/1.png',
        'assets/projects/pergadit/2.png',
        'assets/projects/pergadit/3.png',
        'assets/projects/pergadit/4.png',
        'assets/projects/pergadit/5.png',
        'assets/projects/pergadit/6.png',
        'assets/projects/pergadit/7.png',
        'assets/projects/pergadit/8.png',
        'assets/projects/pergadit/9.png',
        'assets/projects/pergadit/10.png',
        'assets/projects/pergadit/11.png'
      ],
      videos: [],
      title:'Përgadit me zemër',
      subtitle:'Floil',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.\nWith a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.\nExplore some of our most successful projects for various clients below.',
    },
    {
      id: 28,
      name: 'Make wish',
      category: 'Campaigns',
      images: ['assets/projects/makewish/1.png',
        'assets/projects/makewish/2.png',
        'assets/projects/makewish/3.png',
        'assets/projects/makewish/4.png',
        'assets/projects/makewish/5.png',
        'assets/projects/makewish/6.png',
        'assets/projects/makewish/7.png',
        'assets/projects/makewish/8.png',
        'assets/projects/makewish/9.png',
        'assets/projects/makewish/10.png',
        'assets/projects/makewish/11.png',
        'assets/projects/makewish/12.png',
        'assets/projects/makewish/13.png',
        'assets/projects/makewish/14.png',
        'assets/projects/makewish/15.png'
      ],
      videos: [],
      title:'#makeawish',
      subtitle:'Jaffa Champion',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.\nWith a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.\nExplore some of our most successful projects for various clients below.',
    },
    {
      id: 29,
      name: 'Qefi ma i madh se Stambolli',
      category: 'Campaigns',
      images: ['assets/projects/qefistambolli/1.png',
        'assets/projects/qefistambolli/2.png',
        'assets/projects/qefistambolli/3.png',
        'assets/projects/qefistambolli/4.png',
        'assets/projects/qefistambolli/5.png',
        'assets/projects/qefistambolli/6.png',
        'assets/projects/qefistambolli/7.png',
        'assets/projects/qefistambolli/8.png',
        'assets/projects/qefistambolli/9.png',
        'assets/projects/qefistambolli/10.png',
        'assets/projects/qefistambolli/11.png',
        'assets/projects/qefistambolli/12.png',
        'assets/projects/qefistambolli/13.png',
        'assets/projects/qefistambolli/14.png'
      ],
      videos: [],
      title:'Qefi ma i madh se Stambolli',
      subtitle:'Jaffa Champion',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.\nWith a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.\nExplore some of our most successful projects for various clients below.',
    },
    {
      id: 30,
      name: 'Floil',
      category: 'Campaigns',
      images: ['assets/projects/floil/1.png',
        'assets/projects/floil/2.png',
        'assets/projects/floil/3.png',
        'assets/projects/floil/4.png',
        'assets/projects/floil/5.png',
        'assets/projects/floil/6.png',
        'assets/projects/floil/7.png',
        'assets/projects/floil/8.png',
        'assets/projects/floil/9.png',
        'assets/projects/floil/10.png',
        'assets/projects/floil/11.png',
        'assets/projects/floil/12.png',
        'assets/projects/floil/13.png',
        'assets/projects/floil/14.png',
        'assets/projects/floil/15.png',
        'assets/projects/floil/16.png',
        'assets/projects/floil/17.png',
        'assets/projects/floil/18.png',
        'assets/projects/floil/19.png',
        'assets/projects/floil/20.png'
      ],
      videos: [],
      title:'1 Vjet',
      subtitle:'Floil',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.\nWith a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.\nExplore some of our most successful projects for various clients below.',
    },
    {
      id: 31,
      name: 'Fluidi Coorporation',
      category: 'Social Media',
      images: ['assets/projects/fluidicorps/1.png',
        'assets/projects/fluidicorps/2.png',
        'assets/projects/fluidicorps/3.png',
        'assets/projects/fluidicorps/4.png',
        'assets/projects/fluidicorps/5.png',
        'assets/projects/fluidicorps/6.png',
        'assets/projects/fluidicorps/7.png',
        'assets/projects/fluidicorps/8.png',
        'assets/projects/fluidicorps/9.png',
        'assets/projects/fluidicorps/10.png',
        'assets/projects/fluidicorps/11.png',
        'assets/projects/fluidicorps/12.png',
        'assets/projects/fluidicorps/13.png'
      ],
      videos: [],
      title:'Fluidi Corporation',
      subtitle:'Gjilan, fresh drinks',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 32,
      name: 'Jaffa Montenegro',
      category: 'Social Media',
      images: ['assets/projects/jaffamontes/1.png',
        'assets/projects/jaffamontes/2.png',
        'assets/projects/jaffamontes/3.png',
        'assets/projects/jaffamontes/4.png',
        'assets/projects/jaffamontes/5.png',
        'assets/projects/jaffamontes/6.png',
        'assets/projects/jaffamontes/7.png',
        'assets/projects/jaffamontes/8.png',
        'assets/projects/jaffamontes/9.png',
        'assets/projects/jaffamontes/10.png',
        'assets/projects/jaffamontes/11.png',
        'assets/projects/jaffamontes/12.png',
        'assets/projects/jaffamontes/13.png',
        'assets/projects/jaffamontes/14.png',
        'assets/projects/jaffamontes/15.png',
        'assets/projects/jaffamontes/16.png',
        'assets/projects/jaffamontes/17.png',
        'assets/projects/jaffamontes/18.png',
        'assets/projects/jaffamontes/19.png',
        'assets/projects/jaffamontes/20.png',
        'assets/projects/jaffamontes/21.png',
        'assets/projects/jaffamontes/22.png',
        'assets/projects/jaffamontes/23.png'
      ],
      videos: [],
      title:'Jaffa Montenegro',
      subtitle:'Montenegro, fresh Drink',
      since:'',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 33,
      name: 'RC Cola',
      category: 'Social Media',
      images: ['assets/projects/rccolas/1.png',
        'assets/projects/rccolas/2.png',
        'assets/projects/rccolas/3.png',
        'assets/projects/rccolas/4.png',
        'assets/projects/rccolas/5.png',
        'assets/projects/rccolas/6.png',
        'assets/projects/rccolas/7.png',
        'assets/projects/rccolas/8.png',
        'assets/projects/rccolas/9.png',
        'assets/projects/rccolas/10.png',
        'assets/projects/rccolas/11.png',
        'assets/projects/rccolas/12.png',
        'assets/projects/rccolas/13.png',
        'assets/projects/rccolas/14.png',
        'assets/projects/rccolas/15.png',
        'assets/projects/rccolas/16.png',
        'assets/projects/rccolas/17.png',
        'assets/projects/rccolas/18.png',
        'assets/projects/rccolas/19.png',
        'assets/projects/rccolas/20.png',
        'assets/projects/rccolas/21.png',
        'assets/projects/rccolas/22.png',
        'assets/projects/rccolas/23.png'
      ],
      videos: [],
      title:'RC Drink',
      subtitle:'Gjilan, Fresh drink ',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 34,
      name: 'Floil',
      category: 'Social Media',
      images: ['assets/projects/floils/1.png',
        'assets/projects/floils/2.png',
        'assets/projects/floils/3.png',
        'assets/projects/floils/4.png',
        'assets/projects/floils/5.png',
        'assets/projects/floils/6.png',
        'assets/projects/floils/7.png',
        'assets/projects/floils/8.png',
        'assets/projects/floils/9.png',
        'assets/projects/floils/10.png',
        'assets/projects/floils/11.png',
        'assets/projects/floils/12.png',
        'assets/projects/floils/13.png',
        'assets/projects/floils/14.png',
        'assets/projects/floils/15.png',
        'assets/projects/floils/16.png',
        'assets/projects/floils/17.png',
        'assets/projects/floils/18.png',
        'assets/projects/floils/19.png'
      ],
      videos: [],
      title:'Floil',
      subtitle:'Gjilan, Oil',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 35,
      name: 'Red Rain',
      category: 'Social Media',
      images: ['assets/projects/redrains/1.png',
        'assets/projects/redrains/2.png',
        'assets/projects/redrains/3.png',
        'assets/projects/redrains/4.png',
        'assets/projects/redrains/5.png',
        'assets/projects/redrains/6.png',
        'assets/projects/redrains/7.png',
        'assets/projects/redrains/8.png',
        'assets/projects/redrains/9.png',
        'assets/projects/redrains/10.png',
        'assets/projects/redrains/11.png',
        'assets/projects/redrains/12.png',
        'assets/projects/redrains/13.png',
        'assets/projects/redrains/14.png',
        'assets/projects/redrains/15.png',
        'assets/projects/redrains/16.png',
        'assets/projects/redrains/17.png',
        'assets/projects/redrains/18.png',
        'assets/projects/redrains/19.png',
        'assets/projects/redrains/20.png'
      ],
      videos: [],
      title:'Red Rain',
      subtitle:'Gjilan, Energy Drink',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 36,
      name: 'Jaffa Champion',
      category: 'Social Media',
      images: ['assets/projects/jaffachamps/1.png',
        'assets/projects/jaffachamps/2.png',
        'assets/projects/jaffachamps/3.png',
        'assets/projects/jaffachamps/4.png',
        'assets/projects/jaffachamps/5.png',
        'assets/projects/jaffachamps/6.png',
        'assets/projects/jaffachamps/7.png',
        'assets/projects/jaffachamps/8.png',
        'assets/projects/jaffachamps/9.png',
        'assets/projects/jaffachamps/10.png',
        'assets/projects/jaffachamps/11.png',
        'assets/projects/jaffachamps/12.png',
        'assets/projects/jaffachamps/13.png',
        'assets/projects/jaffachamps/14.png',
        'assets/projects/jaffachamps/15.png',
        'assets/projects/jaffachamps/16.png',
        'assets/projects/jaffachamps/17.png',
        'assets/projects/jaffachamps/18.png',
        'assets/projects/jaffachamps/19.png',
        'assets/projects/jaffachamps/20.png',
        'assets/projects/jaffachamps/21.png',
        'assets/projects/jaffachamps/22.png',
        'assets/projects/jaffachamps/23.png',
        'assets/projects/jaffachamps/24.png'
      ],
      videos: [],
      title:'Jaffa Champion',
      subtitle:'Gjilan, fresh drink',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 37,
      name: 'Boho',
      category: 'Branding',
      images: ['assets/projects/boho/1.png',
        'assets/projects/boho/2.png',
        'assets/projects/boho/3.png',
        'assets/projects/boho/4.png',
        'assets/projects/boho/5.png',
        'assets/projects/boho/6.png',
        'assets/projects/boho/7.png',
        'assets/projects/boho/8.png',
        'assets/projects/boho/9.png'
      ],
      videos: [],
      title:'boho furniture',
      subtitle:'Macedonia, Furniture',
      since:'',
      description:'A well-known company in Macedonia for producing interiors, trusted us with the development of its brand.\nWe created an attractive identity that reflects the style and quality of their products designed to transform indoor spaces.\nDuring the work process, we created a logo and promotional materials that represent the aesthetics and functionality of Boho interiors, helping to strengthen the brand\'s image and increase market visibility.',
    },
    {
      id: 38,
      name: 'tëvë',
      category: 'Branding',
      images: ['assets/projects/teve/1.png',
        'assets/projects/teve/2.png',
        'assets/projects/teve/3.png',
        'assets/projects/teve/4.png',
        'assets/projects/teve/5.png',
        'assets/projects/teve/6.png',
        'assets/projects/teve/7.png',
        'assets/projects/teve/8.png',
        'assets/projects/teve/9.png'
      ],
      videos: [],
      title:'tëvë',
      subtitle:'',
      since:'2018',
      description:'A national television. We created an identity that reflects the seriousness and commitment of Teve 1 towards providing quality and informative content.\nDuring the work process, we designed a logo and promotional materials that reflect the professionalism and reliability of Teve 1, helping to strengthen the brand\'s identity and increase its media presence.',
    },
    {
      id: 39,
      name: 'Sekuence',
      category: 'Branding',
      images: ['assets/projects/sekuence/1.png',
        'assets/projects/sekuence/2.png',
        'assets/projects/sekuence/3.png',
        'assets/projects/sekuence/4.png',
        'assets/projects/sekuence/5.png',
        'assets/projects/sekuence/6.png',
        'assets/projects/sekuence/7.png',
        'assets/projects/sekuence/8.png'
      ],
      videos: [],
      title:'Sekuence',
      subtitle:'Kosova, Video Production Studio',
      since:'2018',
      description:'We created a strong and modern identity that emphasizes the company\'s commitment to creativity and quality in promoting renowned figures. During the work process, we designed a logo and promotional materials that represent the exclusive and dynamic nature of Sequencia, enhancing the brand\'s visibility and appeal in the media and marketing industry.',
    },
    {
      id: 40,
      name: 'Kids',
      category: 'Campaigns',
      images: ['assets/projects/kids/1.png',
        'assets/projects/kids/2.png',
        'assets/projects/kids/3.png',
        'assets/projects/kids/4.png',
        'assets/projects/kids/5.png',
        'assets/projects/kids/6.png',
        'assets/projects/kids/7.png',
        'assets/projects/kids/8.png',
        'assets/projects/kids/9.png',
        'assets/projects/kids/10.png',
        'assets/projects/kids/11.png',
        'assets/projects/kids/12.png',
        'assets/projects/kids/13.png',
        'assets/projects/kids/14.png',
        'assets/projects/kids/15.png'
      ],
      videos: [],
      title:'Kids',
      subtitle:'Jaffa Champion',
      since:'2018',
      description:'We have created and managed complex campaigns, leading every step from the initial idea to final realization.\nWith a focused and detailed approach, we have transformed concepts into extraordinary campaigns that have captured attention and delivered excellent results.\nExplore some of our most successful projects for various clients below.',
    },
    {
      id: 41,
      name: 'Bus Station',
      category: 'Outdoor',
      images: ['assets/projects/outdoor/1.png',
        'assets/projects/outdoor/2.png',
        'assets/projects/outdoor/3.png',
        'assets/projects/outdoor/4.png'
      ],
      videos: [],
      title:'Bus Station',
      subtitle:'Bus Station site prints',
      since:'Over 3000+',
      description:'We have executed super creative projects for well-known businesses, including murals, billboards, city lights, and bus stations.\nThese outstanding works have brought unique and unforgettable presentations to public spaces. Below, you can see some of our most successful implementations.',
    },
    {
      id: 42,
      name: 'Jaffa Champion',
      category: 'Outdoor',
      images: ['assets/projects/outdoor2/1.png',
        'assets/projects/outdoor2/2.png',
        'assets/projects/outdoor2/3.png',
        'assets/projects/outdoor2/4.png',
        'assets/projects/outdoor2/5.png',
        'assets/projects/outdoor2/6.png',
        'assets/projects/outdoor2/7.png',
        'assets/projects/outdoor2/8.png',
        'assets/projects/outdoor2/9.png',
        'assets/projects/outdoor2/10.png',
        'assets/projects/outdoor2/11.png',
        'assets/projects/outdoor2/12.png',
        'assets/projects/outdoor2/13.png'
      ],
      videos: [],
      title:'Jaffa Champion',
      subtitle:'Fresh Drink',
      since:'Over 3000+',
      description:'We have executed super creative projects for well-known businesses, including murals, billboards, city lights, and bus stations.\nThese outstanding works have brought unique and unforgettable presentations to public spaces. Below, you can see some of our most successful implementations.',
    },
    {
      id: 43,
      name: 'RC Cola',
      category: 'Outdoor',
      images: ['assets/projects/outdoor3/1.png',
        'assets/projects/outdoor3/2.png',
        'assets/projects/outdoor3/3.png',
        'assets/projects/outdoor3/4.png',
        'assets/projects/outdoor3/5.png',
        'assets/projects/outdoor3/6.png',
        'assets/projects/outdoor3/7.png',
        'assets/projects/outdoor3/8.png',
        'assets/projects/outdoor3/9.png',
        'assets/projects/outdoor3/10.png',
        'assets/projects/outdoor3/11.png',
        'assets/projects/outdoor3/12.png'
      ],
      videos: [],
      title:'RC Cola',
      subtitle:'Fresh Drink',
      since:'Over 3000+',
      description:'We have executed super creative projects for well-known businesses, including murals, billboards, city lights, and bus stations.\nThese outstanding works have brought unique and unforgettable presentations to public spaces. Below, you can see some of our most successful implementations.',
    },
    {
      id: 44,
      name: 'Floil',
      category: 'Outdoor',
      images: ['assets/projects/outdoor4/1.png',
        'assets/projects/outdoor4/2.png',
        'assets/projects/outdoor4/3.png',
        'assets/projects/outdoor4/4.png',
        'assets/projects/outdoor4/5.png',
        'assets/projects/outdoor4/6.png',
        'assets/projects/outdoor4/7.png'
      ],
      videos: [],
      title:'Floil',
      subtitle:'Fresh Drink',
      since:'Over 3000+',
      description:'We have executed super creative projects for well-known businesses, including murals, billboards, city lights, and bus stations.\nThese outstanding works have brought unique and unforgettable presentations to public spaces. Below, you can see some of our most successful implementations.',
    },
    {
      id: 45,
      name: 'Red Rain',
      category: 'Outdoor',
      images: ['assets/projects/outdoor5/1.png',
        'assets/projects/outdoor5/2.png',
        'assets/projects/outdoor5/3.png',
        'assets/projects/outdoor5/4.png',
        'assets/projects/outdoor5/5.png'
      ],
      videos: [],
      title:'Red Rain',
      subtitle:'Fresh Drink',
      since:'Over 3000+',
      description:'We have executed super creative projects for well-known businesses, including murals, billboards, city lights, and bus stations.\nThese outstanding works have brought unique and unforgettable presentations to public spaces. Below, you can see some of our most successful implementations.',
    },
    {
      id: 46,
      name: 'Floil',
      category: 'Event',
      images: ['assets/projects/event1/1.png',
        'assets/projects/event1/2.png',
        'assets/projects/event1/3.png',
        'assets/projects/event1/4.png',
        'assets/projects/event1/5.png',
        'assets/projects/event1/6.png',
        'assets/projects/event1/7.png',
        'assets/projects/event1/8.png',
        'assets/projects/event1/9.png',
        'assets/projects/event1/10.png',
        'assets/projects/event1/11.png',
        'assets/projects/event1/12.png',
        'assets/projects/event1/13.png',
        'assets/projects/event1/14.png'
      ],
      videos: [],
      title:'Floil',
      subtitle:'Prishtine',
      since:'',
      description:'',
    },
    {
      id: 47,
      name: 'Floil, JaffaPlus, JaffaChampion, Rc Cola, Red Rain',
      category: 'Event',
      images: ['assets/projects/event2/1.png',
        'assets/projects/event2/2.png',
        'assets/projects/event2/3.png',
        'assets/projects/event2/4.png',
        'assets/projects/event2/5.png',
        'assets/projects/event2/6.png',
        'assets/projects/event2/7.png',
        'assets/projects/event2/8.png',
        'assets/projects/event2/9.png',
        'assets/projects/event2/10.png',
        'assets/projects/event2/11.png',
        'assets/projects/event2/12.png'
      ],
      videos: [],
      title:'Floil',
      subtitle:'',
      since:'',
      description:'',
    },
    {
      id: 48,
      name: 'Jaffa Macedonia',
      category: 'Social Media',
      images: ['assets/projects/jaffamacs/1.png',
        'assets/projects/jaffamacs/2.png',
        'assets/projects/jaffamacs/3.png',
        'assets/projects/jaffamacs/4.png',
        'assets/projects/jaffamacs/5.png',
        'assets/projects/jaffamacs/6.png',
        'assets/projects/jaffamacs/7.png',
        'assets/projects/jaffamacs/8.png',
        'assets/projects/jaffamacs/9.png',
        'assets/projects/jaffamacs/10.png',
        'assets/projects/jaffamacs/11.png',
        'assets/projects/jaffamacs/12.png',
        'assets/projects/jaffamacs/13.png',
        'assets/projects/jaffamacs/14.png',
        'assets/projects/jaffamacs/15.png',
        'assets/projects/jaffamacs/16.png',
        'assets/projects/jaffamacs/17.png',
        'assets/projects/jaffamacs/18.png',
        'assets/projects/jaffamacs/19.png',
        'assets/projects/jaffamacs/20.png',
        'assets/projects/jaffamacs/21.png',
        'assets/projects/jaffamacs/22.png',
        'assets/projects/jaffamacs/23.png',
        'assets/projects/jaffamacs/24.png'
      ],
      videos: [],
      title:'Jaffa Macedonia',
      subtitle:'Macedonia, fresh drink',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 49,
      name: 'Relux',
      category: 'Social Media',
      images: ['assets/projects/relux/1.png',
        'assets/projects/relux/2.png',
        'assets/projects/relux/3.png',
        'assets/projects/relux/4.png',
        'assets/projects/relux/5.png',
        'assets/projects/relux/6.png',
        'assets/projects/relux/7.png',
        'assets/projects/relux/8.png',
        'assets/projects/relux/9.png',
        'assets/projects/relux/10.png',
        'assets/projects/relux/11.png',
        'assets/projects/relux/12.png',
        'assets/projects/relux/13.png',
        'assets/projects/relux/14.png',
        'assets/projects/relux/15.png',
        'assets/projects/relux/16.png',
        'assets/projects/relux/17.png',
        'assets/projects/relux/18.png',
        'assets/projects/relux/19.png',
        'assets/projects/relux/20.png'
      ],
      videos: [],
      title:'Relux',
      subtitle:'Kosova, Fascade & Colors',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 50,
      name: 'ExCoins',
      category: 'Social Media',
      images: ['assets/projects/excoins/1.png',
        'assets/projects/excoins/2.png',
        'assets/projects/excoins/3.png',
        'assets/projects/excoins/4.png',
        'assets/projects/excoins/5.png',
        'assets/projects/excoins/6.png',
        'assets/projects/excoins/7.png',
        'assets/projects/excoins/8.png',
        'assets/projects/excoins/9.png',
        'assets/projects/excoins/10.png',
        'assets/projects/excoins/11.png',
        'assets/projects/excoins/12.png',
        'assets/projects/excoins/13.png',
        'assets/projects/excoins/14.png',
        'assets/projects/excoins/15.png',
        'assets/projects/excoins/16.png',
        'assets/projects/excoins/17.png',
        'assets/projects/excoins/18.png',
        'assets/projects/excoins/19.png',
        'assets/projects/excoins/20.png'
      ],
      videos: [],
      title:'ExCoins',
      subtitle:'Gjilan, crypto exchange',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },
    {
      id: 51,
      name: 'Andulino',
      category: 'Social Media',
      images: ['assets/projects/andulino/1.png',
        'assets/projects/andulino/2.png',
        'assets/projects/andulino/3.png',
        'assets/projects/andulino/4.png',
        'assets/projects/andulino/5.png',
        'assets/projects/andulino/6.png',
        'assets/projects/andulino/7.png',
        'assets/projects/andulino/8.png',
        'assets/projects/andulino/9.png',
        'assets/projects/andulino/10.png',
        'assets/projects/andulino/11.png',
        'assets/projects/andulino/12.png',
        'assets/projects/andulino/13.png',
        'assets/projects/andulino/14.png'
      ],
      videos: [],
      title:'Andulino',
      subtitle:'switzerland, steak house',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    }, {
      id: 52,
      name: 'Ambero',
      category: 'Social Media',
      images: ['assets/projects/amberos/1.png',
        'assets/projects/amberos/2.png',
        'assets/projects/amberos/3.png',
        'assets/projects/amberos/4.png',
        'assets/projects/amberos/5.png',
        'assets/projects/amberos/6.png',
        'assets/projects/amberos/7.png',
        'assets/projects/amberos/8.png',
        'assets/projects/amberos/9.png',
        'assets/projects/amberos/10.png',
        'assets/projects/amberos/11.png',
        'assets/projects/amberos/12.png',
        'assets/projects/amberos/13.png',
        'assets/projects/amberos/14.png',
        'assets/projects/amberos/15.png'
      ],
      videos: [],
      title:'Ambero',
      subtitle:'Prishtina, Furniture',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },  {
      id: 53,
      name: 'Oberal',
      category: 'Social Media',
      images: ['assets/projects/oberal/1.png',
        'assets/projects/oberal/2.png',
        'assets/projects/oberal/3.png',
        'assets/projects/oberal/4.png',
        'assets/projects/oberal/5.png',
        'assets/projects/oberal/6.png',
        'assets/projects/oberal/7.png',
        'assets/projects/oberal/8.png',
        'assets/projects/oberal/9.png',
        'assets/projects/oberal/10.png',
        'assets/projects/oberal/11.png'
      ],
      videos: [],
      title:'Oberal',
      subtitle:'Prishtina, Llaminate & doors',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    }, {
      id: 54,
      name: 'Luboteni',
      category: 'Social Media',
      images: ['assets/projects/lubo/1.png',
        'assets/projects/lubo/2.png',
        'assets/projects/lubo/3.png',
        'assets/projects/lubo/4.png',
        'assets/projects/lubo/5.png',
        'assets/projects/lubo/6.png',
        'assets/projects/lubo/7.png',
        'assets/projects/lubo/8.png',
        'assets/projects/lubo/9.png',
        'assets/projects/lubo/10.png',
        'assets/projects/lubo/11.png'
      ],
      videos: [],
      title:'Lubeteni',
      subtitle:'Kosova, Natural spring water',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    }, {
      id: 55,
      name: 'Tire Center',
      category: 'Social Media',
      images: ['assets/projects/tirec/1.png',
        'assets/projects/tirec/2.png',
        'assets/projects/tirec/3.png',
        'assets/projects/tirec/4.png',
        'assets/projects/tirec/5.png',
        'assets/projects/tirec/6.png',
        'assets/projects/tirec/7.png',
        'assets/projects/tirec/8.png',
        'assets/projects/tirec/9.png'
      ],
      videos: [],
      title:'Tire Center',
      subtitle:'Kosova, tire shop',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    },{
      id: 56,
      name: 'Telkos',
      category: 'Social Media',
      images: ['assets/projects/telkos/1.png',
        'assets/projects/telkos/2.png',
        'assets/projects/telkos/3.png',
        'assets/projects/telkos/4.png',
        'assets/projects/telkos/5.png',
        'assets/projects/telkos/6.png',
        'assets/projects/telkos/7.png',
        'assets/projects/telkos/8.png',
        'assets/projects/telkos/9.png'
      ],
      videos: [],
      title:'Telkos',
      subtitle:'Kosova, Internet Provider',
      since:'2018',
      description:'We have managed and continue to manage social networks for various clients, providing customized strategies and engaging content that keeps brands at the forefront of the audience\'s mind.\nFrom content creation to performance analysis and improvement, every step is meticulously cared for.\nCheck out some of our successes in social media management below.',
    }, {
      id: 57,
      name: 'Space Exploration',
      category: 'Print',
      images: ['assets/projects/spaces/1.png',
        'assets/projects/spaces/2.png',
        'assets/projects/spaces/3.png',
        'assets/projects/spaces/4.png',
        'assets/projects/spaces/5.png',
        'assets/projects/spaces/6.png',
        'assets/projects/spaces/7.png'
      ],
      videos: [],
      title:'Space Exploration',
      subtitle:'Via Space Holdings',
      since:'2018',
      description:'Our printed catalogue showcases our diverse portfolio, featuring high-quality printing services, including brochures, reports, planners, invitations, 3D realizations, roll-up and pop-up banners, murals, and laser printing.\nExplore the range of possibilities to bring your vision to life.',
    },{
      id: 58,
      name: 'Oberal',
      category: 'Print',
      images: ['assets/projects/oberalc/1.png',
        'assets/projects/oberalc/2.png',
        'assets/projects/oberalc/3.png',
        'assets/projects/oberalc/4.png',
        'assets/projects/oberalc/5.png',
        'assets/projects/oberalc/6.png',
        'assets/projects/oberalc/7.png'
      ],
      videos: [],
      title:'Oberal',
      subtitle:'Prishtina',
      since:'2018',
      description:'Our printed catalogue showcases our diverse portfolio, featuring high-quality printing services, including brochures, reports, planners, invitations, 3D realizations, roll-up and pop-up banners, murals, and laser printing.\nExplore the range of possibilities to bring your vision to life.',
    },{
      id: 59,
      name: 'Ambero',
      category: 'Print',
      images: ['assets/projects/amberoc/1.png',
        'assets/projects/amberoc/2.png',
        'assets/projects/amberoc/3.png',
        'assets/projects/amberoc/4.png',
        'assets/projects/amberoc/5.png',
        'assets/projects/amberoc/6.png',
        'assets/projects/amberoc/7.png'
      ],
      videos: [],
      title:'Ambero',
      subtitle:'Prishtina, Furniture',
      since:'2018',
      description:'Our printed catalogue showcases our diverse portfolio, featuring high-quality printing services, including brochures, reports, planners, invitations, 3D realizations, roll-up and pop-up banners, murals, and laser printing.\nExplore the range of possibilities to bring your vision to life.',
    }, {
      id: 60,
      name: 'Relux',
      category: 'Print',
      images: ['assets/projects/reluxc/1.png',
        'assets/projects/reluxc/2.png',
        'assets/projects/reluxc/3.png',
        'assets/projects/reluxc/4.png',
        'assets/projects/reluxc/5.png',
        'assets/projects/reluxc/6.png',
        'assets/projects/reluxc/7.png',
        'assets/projects/reluxc/8.png'
      ],
      videos: [],
      title:'Relux',
      subtitle:'Prishtina, Furniture',
      since:'2018',
      description:'Our printed catalogue showcases our diverse portfolio, featuring high-quality printing services, including brochures, reports, planners, invitations, 3D realizations, roll-up and pop-up banners, murals, and laser printing.\nExplore the range of possibilities to bring your vision to life.',
    }, {
      id: 57,
      name: 'Space Exploration',
      category: 'Print',
      images: ['assets/projects/spaces/1.png',
        'assets/projects/spaces/2.png',
        'assets/projects/spaces/3.png',
        'assets/projects/spaces/4.png',
        'assets/projects/spaces/5.png',
        'assets/projects/spaces/6.png',
        'assets/projects/spaces/7.png'
      ],
      videos: [],
      title:'Space Exploration',
      subtitle:'Via Space Holdings',
      since:'2018',
      description:'Our printed catalogue showcases our diverse portfolio, featuring high-quality printing services, including brochures, reports, planners, invitations, 3D realizations, roll-up and pop-up banners, murals, and laser printing.\nExplore the range of possibilities to bring your vision to life.',
    }






  ];
  