<div class="center">
    <div class="bck-white">
        <div class="service-container">
            <div class="title">
                Our services
            </div>
            <div class="services-wrapper"  >
                <div class="service-item first" style="background-color: #EA2E30; color: rgb(255, 255, 255);">
                    <h3 class="service-title first">Graphic &amp; Design</h3>
                    <div class="text-day-wrapper first" style="background-color: rgb(245, 244, 240); color: rgb(39, 38, 43);">
                        <div class="text-day">
                            01
                        </div>
                    </div>
                    <div class="plan-description">
                        <div class="service-list-text first">
                            With a wide team of professional designers, 
                            we offer creative and meticulously detailed designs for you. 
                            Uniqueness and global trends are the principles that guide us.
                        </div>
                    </div>
                </div>
                <div class="service-item second" style="background-color: rgb(245, 244, 240); color: rgb(51, 51, 51); width: 40em;">
                    <h3 class="service-title second">Branding</h3>
                    <div class="plan-description">
                        <div class="service-list-text second">
                            Branding is one of the fields where Advors Agency has developed significant specialization over the years. 
                            Our portfolio includes a large number of corporations and companies for which we have created brand books or rebranded.
                        </div>
                    </div>
                    <div class="text-day-wrapper second" style="background-color: rgb(245, 244, 240); color: rgb(39, 38, 43);">
                        <div class="text-day">
                            02
                        </div>
                    </div>
                </div>
                <div class="service-item third" style="background-color: rgb(245, 244, 240); color: rgb(51, 51, 51); width: 40em;">
                    <h3 class="service-title third">Web & App Development</h3>
                    <div class="plan-description">
                        <div class="service-list-text third">
                            We specialize in developing websites and applications for local and international companies. 
                            Our work's global recognition reflects our team's expertise, 
                            covering everything from ideation to design, content, programming, security, and testing.
                        </div>
                    </div>
                    <div class="text-day-wrapper third" style="background-color: rgb(245, 244, 240); color: rgb(39, 38, 43);">
                        <div class="text-day">
                            03
                        </div>
                    </div>
                </div>
                <div class="service-item last" style="background-color: rgb(245, 244, 240); color: rgb(51, 51, 51); width: 40em;">
                    <h3 class="service-title last">
                        Social Media
                    </h3>
                    <div class="plan-description">
                        <div class="service-list-text last">
                            Me një ekipë të gjerë të dizajnerave profesional, ofrojmë dizajne kreative dhe të menduara deri në detajin më të vogël për ju
                        </div>
                    </div>
                    <div class="text-day-wrapper last" style="background-color: rgb(245, 244, 240); color: rgb(39, 38, 43);">
                        <div class="text-day">
                            04
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="services-wrapper-two">
                <div class="service-item-two first" style="width: 80em; background-color: #EA2E30; color: rgb(255, 255, 255);">
                    <h3 class="service-title-two first">Digital Marketing</h3>
                    <div class="text-day-wrapper-two first" style="background-color: rgb(245, 244, 240); color: rgb(39, 38, 43);">
                        <div class="text-day-two">
                            05
                        </div>
                    </div>
                    <div class="plan-description-two">
                        <div class="service-list-text-two first">
                            We craft creative, trend-driven strategies that refresh every three months. 
                            Our marketing experts manage your online presence, tailoring content to boost your brand's visibility.
                        </div>
                    </div>
                </div>
                <div class="service-item-two second" style="background-color: rgb(245, 244, 240); color: rgb(51, 51, 51); width: 40em;">
                    <h3 class="service-title-two second">Production</h3>
                    <div class="plan-description-two">
                        <div class="service-list-text-two second">
                            Advors Agency meets client demands with high-quality, fast production of photoshoots, 
                            TV commercials, shows, live broadcasts, event filming, and custom videos.
                        </div>
                    </div>
                    <div class="text-day-wrapper-two second" style="background-color: rgb(245, 244, 240); color: rgb(39, 38, 43);">
                        <div class="text-day-two">
                            06
                        </div>
                    </div>
                </div>
                <div class="service-item-two third" style="background-color: rgb(245, 244, 240); color: rgb(51, 51, 51); width: 40em;">
                    <h3 class="service-title-two third">Advertising, 2D&3D Animations</h3>
                    <div class="plan-description-two">
                        <div class="service-list-text-two third">
                            Since 2018, we've created video ads and 2D/3D animations for TV, 
                            social media, and outdoor ads, delivering top quality and creativity to clients worldwide.
                        </div>
                    </div>
                    <div class="text-day-wrapper-two third" style="background-color: rgb(245, 244, 240); color: rgb(39, 38, 43);">
                        <div class="text-day-two">
                            07
                        </div>
                    </div>
                </div>
                <div class="service-item-two last" style="background-color: rgb(245, 244, 240); color: rgb(51, 51, 51); width: 40em;">
                    <h3 class="service-title-two last">
                        Campaigns & Events
                    </h3>
                    <div class="plan-description-two">
                        <div class="service-list-text-two last">
                            Advors Agency turns your ideas into successful campaigns and organizes unique, impactful events.
                        </div>
                    </div>
                    <div class="text-day-wrapper-two last" style="background-color: rgb(245, 244, 240); color: rgb(39, 38, 43);">
                        <div class="text-day-two">
                            08
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</div>