import { ChangeDetectorRef, Component, ElementRef, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProjectService } from '../project.services';
import { CommonModule, isPlatformBrowser, Location } from '@angular/common';
import { gsap, ScrollTrigger } from 'gsap/all';
import { filter, Subscription } from 'rxjs';

gsap.registerPlugin(ScrollTrigger);


@Component({
  selector: 'app-projectv3',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './projectv3.component.html',
  styleUrl: './projectv3.component.css'
})
export class Projectsv3Component {

  @ViewChild('rightColumn') rightColumn!: ElementRef;
  @ViewChild('zIndex3') zIndex3!: ElementRef;
  @ViewChild('leftColumn') leftColumn!: ElementRef;
  @ViewChild('container') container!: ElementRef;
  projectId: string | null = null;
  project: any = null;
  mediaList: (string | { type: 'video'; src: string })[] = [];
  private animation!: GSAPTimeline; 
  

  constructor(private route: ActivatedRoute, private projectService: ProjectService, @Inject(PLATFORM_ID) private platformId: Object, private location: Location,private router: Router, private cd: ChangeDetectorRef) {

    

  }

  ngOnInit(): void {
    window.scrollTo(0,0);
    this.projectId = this.route.snapshot.paramMap.get('id');
    this.route.queryParams.subscribe(params => {
      const p = params['p'];
      if (p == 0) {
        this.router.navigateByUrl(`/project/${this.projectId}?p=1`).then(() => {
          window.location.reload();
        });
      }
    });

    if (this.projectId !== null) {
      const num = parseInt(this.projectId, 10);
      if (!isNaN(num)) {
        this.project = this.projectService.getProjectById(num);
        const { images, videos } = this.project!;

        const maxLength = Math.max(images.length, videos.length);
        for (let i = 0; i < maxLength; i++) {
          if (i < images.length) {
            if (i == 0) {
              continue
            }
            this.mediaList.push(images[i]); // Add image
          }
          if (i < videos.length) {
            this.mediaList.push({ type: 'video', src: videos[i] }); // Add video
          }
        }
      }
    } else {
      console.log("Project ID is null");
    }
  }


  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const dp = window.innerWidth;
      if (dp > 500) {
        this.initScrollTrigger();
      }
    }
  }

  initScrollTrigger(): void {

    const leftColumn = this.leftColumn.nativeElement;
    const rightColumn = this.rightColumn.nativeElement;
    const zIndex3 = this.zIndex3.nativeElement;

    this.animation = gsap.timeline({
      scrollTrigger: {
        trigger: zIndex3,
        start: 'top top',
        end: 'bottom+=250px bottom',
        scrub: true,
        pin: leftColumn,
      },
    });
    this.animation.to(rightColumn, {
      // Add your tween properties here
      opacity: 1,
      duration: 1,
    });

    this.cd.detectChanges(); 
  }

  goBack(): void {
    this.router.navigate(['works']);
  }

  getFormattedDescription() {
    return this.project.description.replace(/\n/g, '<br> <br> <br>');
  }

  isVideo(media: string | { type: 'video'; src: string }): media is { type: 'video'; src: string } {
    return typeof media === 'object' && media.type === 'video';
  }

}
