import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Output, PLATFORM_ID } from '@angular/core';
import { AppComponent } from '../app.component';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './testimonials.component.html',
  styleUrl: './testimonials.component.css'
})
export class TestimonialsComponent {

  testimonials = [
    {
      avatar: 'assets/testimonials.svg',
      name: 'emri mbiemri',
      designation: 'Jaffa Champion',
      text: "We won't just be executors. We'll be your partners, we promise. If you're not convinced, check out our verified testimonials from around the world about working with us."
    },
    {
      avatar: 'assets/testimonials.svg',
      name: 'emri mbiemri',
      designation: 'Jaffa Champion',
      text: "We won't just be executors. We'll be your partners, we promise. If you're not convinced, check out our verified testimonials from around the world about working with us."
    },
    {
      avatar: 'assets/testimonials.svg',
      name: 'john doe',
      designation: 'Marketing Guru',
      text: "We won't just be executors. We'll be your partners, we promise. If you're not convinced, check out our verified testimonials from around the world about working with us."
    },
    {
      avatar: 'assets/testimonials.svg',
      name: 'jane doe',
      designation: 'Creative Director',
      text: "We won't just be executors. We'll be your partners, we promise. If you're not convinced, check out our verified testimonials from around the world about working with us."
    }
    // Add more testimonials as needed
  ];
  visibleTestimonials: any;
  activeTestimonialIndex = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private appComponent: AppComponent) {
    
  }

  @Output() loaded = new EventEmitter();

  @HostListener('window:load')
  onWindowLoad() {
    this.loaded.emit('true');
  }
  
  nextTestimonialSlide() {
    this.activeTestimonialIndex = (this.activeTestimonialIndex + 1) % this.testimonials.length;
    this.updateVisibleTestimonials();
  }
  
  prevTestimonialSlide() {
    this.activeTestimonialIndex = (this.activeTestimonialIndex - 1 + this.testimonials.length) % this.testimonials.length;
    this.updateVisibleTestimonials();
  }
  
  updateVisibleTestimonials() {
    if (isPlatformBrowser(this.platformId)) {
      const dp = window.innerWidth;
      const nextTestimonials = dp < 451 
        ? [this.testimonials[this.activeTestimonialIndex]] 
        : [this.testimonials[this.activeTestimonialIndex], this.testimonials[(this.activeTestimonialIndex + 1) % this.testimonials.length]];
  
      this.visibleTestimonials = nextTestimonials.map((testimonial, index) => {
        // Use a class for active testimonial to trigger animation
        return { ...testimonial, active: index === 0 }; // Only first testimonial gets 'active' class
      });
    }
  }

  ngOnInit(): void {
    this.updateVisibleTestimonials();
   }

   openModal(): void {
    this.appComponent.openModal();
  }

  // Close modal and restore body scroll
  closeModal(): void {
    this.appComponent.closeModal(false);
  }
}
