<div class="main-footer">
    <div class="footer-content">
       <div class="footer-inner-content">
            <div class="footer-left">
                <img src="../../assets/footer_logo.svg">
            </div>
            <div class="footer-right">
                <div class="column-left">
                    <div class="column-header">
                        Navigation
                    </div>
                    <div class="column-content">
                        <a class="column-content" style="text-decoration: none;" routerLink="/" [queryParams]="{ scrollTo: 'service' }" >Services</a>
                    </div>
                    <div class="column-content">
                        <a class="column-content" style="text-decoration: none;" routerLink="/works" >Our Works</a>
                    </div>
                    <div class="column-content" >
                        <a class="column-content" style="text-decoration: none;cursor: pointer;"  (click)="openModal()" >Contact</a>
                    </div>
                </div>
                <div class="column-right">
                    <div class="column-header">
                        Contact
                    </div>
                    <div class="column-content">
                        <a href="https://www.linkedin.com/company/advors-agency/">
                            <img src="../../assets/linkedin.svg" class="icon-size">
                        </a>
                       <a href="https://www.instagram.com/advors_agency">
                            <img src="../../assets/instagramicon.svg" class="icon-size" style="margin-left: 5px;">
                       </a>
                    </div>
                    <div class="column-content">
                        <a href="https://www.youtube.com/channel/UCBQtHdTRBc6QkewHkGUHE1g">
                            <img src="../../assets/youtube.svg" class="icon-size">
                        </a>
                        <a href="https://www.facebook.com/advors/">
                            <img src="../../assets/facebook.svg" class="icon-size" style="margin-left: 5px;">
                        </a>
                    </div>
                    <div class="column-content">
                        <img src="../../assets/lovely.svg" class="icon-size">
                    </div>
                </div>
            </div>
       </div>
    </div>
</div>