<div *ngIf="modalOpen">
    <div class="modal-backdrop" (click)="closeModal()"></div>
    <div class="modal-wrapper" [ngClass]="{ 'open': scrolledEnough }">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="title">Lets get started</h2>
          <button class="close-button" (click)="closeModal()">X</button>
        </div>
        <div class="modal-body">
          <div class="dblock">
            <p class="pone">Fill in the blanks and we'll respond in one business day.</p>
            <form [formGroup]="contactForm" (ngSubmit)="submitForm()" >
              <div class="w80">
                <div class="col">
                  <label class="ponedesc">What's your name?</label>
                  <div class="input-container">
                    <input type="text" formControlName="name" placeholder="Type your name..." class="custom-input" />
                  </div>
                </div>
    
                <div class="col leftMarg" >
                  <label class="ponedesc">How can we contact you?</label>
                  <select formControlName="contactPreference" class="custom-input">
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                  </select>
                </div>
              </div>
    
              <!-- Conditional Email or Phone Input -->
              <div class="row" *ngIf="contactForm.get('contactPreference')?.value === 'email'">
                <label class="ponedesc">Write your Email</label>
                <div class="input-email-container">
                  <input type="email" formControlName="email" placeholder="Your email..." class="custom-input" />
                </div>
              </div>
    
              <div class="row" *ngIf="contactForm.get('contactPreference')?.value === 'phone'">
                <label class="ponedesc">Write your Phone Number</label>
                <div class="input-email-container">
                  <input type="tel" formControlName="phone" placeholder="Your phone..." class="custom-input" />
                </div>
              </div>
    
              <div class="row">
                <label class="ponedesc">Tell us about your project</label>
                <div class="input-tx-container">
                  <textarea formControlName="description" rows="4" placeholder="Project details..." class="custom-tx-input"></textarea>
                </div>
              </div>
    
              <div class="row">
                <label class="ponedesc">I need </label>
                <div class="dgrid">
                  <div *ngFor="let item of items" class="checkbox-container" style="margin-top: 10px;">
                    <!-- <input class="checkbox-input" type="checkbox" [formControlName]="item.value" id="{{ item.value }}" /> -->
                    <div class="checkbox-container">
                      <input 
                        class="checkbox-input" 
                        type="checkbox" 
                        [formControlName]="item.value" 
                        id="{{ item.value }}" 
                      />
                      <label for="{{ item.value }}" class="custom-checkbox"></label>
                    </div>
                    <label class="custom-label" for="{{ item.value }}">{{ item.label }}</label>
                  </div>
                </div>
              </div>
    
              <button type="submit" class="submit-btn" style="margin-top: 20px;">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  