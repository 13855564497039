<div class="z-index2" #zindex>
    <video autoplay muted loop webkit-playsinline playsinline #backgroundVideo id="background-video">
        <source src="../../assets/video/underbackground.mp4" type="video/mp4" >
        Your browser does not support the video tag.
    </video>
    <div class="background-video">
    
    </div>
    
      <div class="main z-index3">
       <div class="w100 dflex h600 justify-center">
            <div class="dblock" @fadeIn>
                <div class="main-title pt200">
                    Design and <br>Advertising agency
                </div>
                <div class="main-description">
                    Helping brands to achieve the unachievable
                </div>
                <div class="w100 dflex justify-center mtop50">
                    <a (click)="openModal()">
                        <div class="button-meet">
                            Let's Meet
                        </div>
                    </a>
                </div>
            </div>
       </div>
    </div>
    
    <div class="video-main" #fadeInVideo>
        <div class="video-mask">
            <video autoplay muted loop webkit-playsinline playsinline #mainVideo id="main-video">
                <source src="../../assets/video/lovely-client.mp4" type="video/mp4" >
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
    
    <app-services (loaded)="onComponentLoaded('services')" class="service" id="service-section"> </app-services>
    
    <app-slideshow (loaded)="onComponentLoaded('slideshow')"> </app-slideshow>
    
    <app-testimonials (loaded)="onComponentLoaded('testimonials')"> </app-testimonials>
    
    <app-spiralball (loaded)="onComponentLoaded('spiralball')"> </app-spiralball>
    
    <app-lovely-client (loaded)="onComponentLoaded('lovelyClient')"></app-lovely-client>
</div>