import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';
import { LoadingService } from './loading.service';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoadingService); // Use Angular's inject function

  loadingService.show(); // Show loader on request start
  return next(req).pipe(
    finalize(() => loadingService.hide()) // Hide loader when request completes
  );
};
