import { ChangeDetectorRef, Component, Input, Renderer2 } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ModalContactComponent } from './modal-contact/modal-contact.component';
import { LoadingService } from './loading.service';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { Router,} from '@angular/router';




@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent, ModalContactComponent,LoadingSpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {

  showFooter:boolean = true;
  showHeader:boolean = true;

  isLoading = true;
  modalData = {
    show: true,
  };

  constructor(private location: Location, private renderer: Renderer2,private loadingService: LoadingService, private cdr: ChangeDetectorRef,private router: Router){


  }

  ngOnInit(): void {

    if (this.location.path().includes('/project/')){
      this.showFooter = false;
    }
    if (this.location.path().includes('/projectv3/')){
      this.showHeader = false;
    }

    setTimeout(() => {
      this.isLoading = false; // Change the state
      this.cdr.detectChanges(); // Manually trigger change detection
    }, 100);

    // this.loadingService.loading$.subscribe((loading) => {
      
    //   if (loading == false && this.isLoading == true) {
    //     this.isLoading = false;
    //   }else if (loading==true && this.isLoading == false ){
    //     this.isLoading = true;
    //   }
    // });

  }



  modalOpen = false;

  openModal(): void {
    this.modalOpen = true;
    this.renderer.addClass(document.body, 'no-scroll');
  }

  // Close modal and restore body scroll
  closeModal(result: any): void {
    this.modalOpen = result;
    this.renderer.removeClass(document.body, 'no-scroll');
  }

}




// 1.	Brand strategy (Strategji brendi)
// 	2.	Social media marketing (Marketing për rrjete sociale)
// 	3.	Video production (Prodhim video)
// 	4.	Animation services (Shërbime animacioni)
// 	5.	Marketing campaigns (Fushata marketingu)
// 	6.	Event planning (Planifikim eventesh)
// 	7.	Content marketing (Marketing përmbajtjeje)
// 	8.	Digital branding (Brending digjital)
// 	9.	Conference management (Menaxhim konferencash)
// 	10.	Graphic design (Dizajn grafik)
// 	11.	Brand identity (Identitet i markës)
// 	12.	Rebranding (Ribrending)
// 	13.	Corporate events (Evente korporative)
// 	14.	Advertising agency (Agjenci reklame)
// 	15.	Social media management (Menaxhim i rrjeteve sociale)
// 	16.	SEO services (Shërbime SEO)
// 	17.	PPC management (Menaxhim PPC)
// 	18.	Influencer marketing (Marketing me influencues)
// 	19.	Brand awareness (Ndërgjegjësimi për markën)
// 	20.	Email marketing (Marketing me email)
// 	21.	TV commercial production (Prodhim reklamash për TV)
// 	22.	Media planning (Planifikim mediash)
// 	23.	Content creation (Krijim përmbajtjeje)
// 	24.	Creative agency (Agjenci kreative)
// 	25.	Public relations (Marrëdhënie me publikun)
// 	26.	Media buying (Blerje mediash)
// 	27.	Online advertising (Reklamim online)
// 	28.	Brand consultancy (Konsulencë për markë)
// 	29.	Campaign management (Menaxhim fushatash)
// 	30.	Art direction (Drejtim artistik)
// 	31.	Marketing strategy (Strategji marketingu)
// 	32.	Digital campaigns (Fushata digjitale)
// 	33.	Social media advertising (Reklamim në rrjete sociale)
// 	34.	Brand activation (Aktivizimi i markës)
// 	35.	Creative direction (Drejtim kreativ)
// 	36.	Event marketing (Marketing për evente)
// 	37.	Digital marketing solutions (Zgjidhje për marketing digjital)
// 	38.	Brand positioning (Pozicionimi i markës)
// 	39.	Visual storytelling (Tregim vizual)
// 	40.	Corporate branding (Brendim për korporata)