import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { WorksComponent } from './works/works.component';
import { ProjectdetailsComponent } from './projectdetails/projectdetails.component';
import { Projectsv2Component } from './projectsv2/projectsv2.component';
import { Projectsv3Component } from './projectv3/projectv3.component';

export const routes: Routes = [
    { path: 'our-services', component: OurServicesComponent},
    { path: 'works', component: WorksComponent},
    // { path: 'project/:id', component: ProjectdetailsComponent},
    { path: 'project/:id', component: Projectsv2Component},
    { path: 'projectv3/:id', component: Projectsv3Component},
    { path: 'about-us', component: AboutUsComponent},
    { path: 'notfound', component: NotFoundComponent},
    { path: '', component: HomeComponent},
    { path: '**', redirectTo: '/notfound' }
];
