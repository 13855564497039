<div class="z-index">
    <video autoplay muted loop webkit-playsinline playsinline #backgroundVideo id="background-video">
        <source src="../../assets/video/bckworks.mp4" type="video/mp4" >
        Your browser does not support the video tag.
    </video>
    <div class="background-video">
    
    </div>
    
    <div class="z-index3">
        <div class="w100 dflex h600 justify-center">
             <div class="dblock">
                 <div class="main-title pt200">
                     Our Works
                 </div>
                 <div class="category-container" [@categoryAnimation]>
                    <div class="categories">
                        <span *ngFor="let category of categories"
                            (click)="filterProjects(category)"
                            [class.selected]="category === selectedCategory">{{ category }}</span>
                    </div>
          
                 </div>
             </div>
        </div>
     </div>
     <div class="pproj">
        <div *ngIf="isMobile" class="category-container-dropd" >
            <div class="categories-dropdown">
                <div class="dropdown-header" (click)="toggleDropdown()">
                  <div class="dflex justify-center dfonthead">
                    {{ selectedCategory || 'Select Category' }}
                  </div>
                  <span [class.rotate]="isDropdownOpen">▼</span>
                </div>
                
                <div *ngIf="isDropdownOpen" class="dropdown-menu z-index5">
                  <span *ngFor="let category of categories"
                        (click)="filterProjects(category)"
                        [class.selected]="category === selectedCategory">{{ category }}
                  </span>
                </div>
              </div>
        </div>

        <div class="projects mmtop z-index4">
          <div *ngFor="let project of filteredProjects" class="project-container" [@projectAnimation]>
            <a (click)="navigateToProject(project.id)">
             <div class="content-wrapper">
                <img [src]="project.images[0]" alt="{{ project.name }}" class="project-img">
                <div class="overlay">
                  <div class="text-in-lines">
                      <span class="title">{{ project.name }} </span>
                      <span class="subtitle">{{ project.subtitle }}</span>
                  </div>
                </div>
             </div>
            </a>
          </div>
        </div>
      </div>
    <div class="ads-withvideo">
        <div class="lovely-video">
            <video autoplay muted loop webkit-playsinline playsinline #lovelyVideo id="lovely-video">
                <source src="../../assets/video/bckworks.mp4" type="video/mp4">
            </video>
            <div class="be-on">
                <div class="text-before-change">
                    Let's make something <span class="changable-text" [@textAnimation]="state">{{ texts[currentTextIndex] }}</span>
                </div>
                <div class="kick-a-project"> 
                    Kick start a project with us today
                </div>
                <a class="discous-project">
                    DISCUSS YOUR PROJECT
                </a>
            </div>
        </div>
    </div>
    
    <div class="margin-top200 bottomHeight">
    
    </div>

</div>