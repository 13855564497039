import { CommonModule } from '@angular/common';
import { Component, OnInit, HostListener,EventEmitter, Renderer2, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

interface ContactFormControls {
  name: string;
  contactPreference: string;
  email: string;
  phone: string;
  description: string;
  [key: string]: any;
}

@Component({
  selector: 'app-modal-contact',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './modal-contact.component.html',
  styleUrl: './modal-contact.component.css'
})
export class ModalContactComponent implements OnInit {
  contactForm!: FormGroup;
  modalOpen: boolean = true; // Example state for modal
  modalHeight: string = '30%'; // Initial height of the modal
  scrolledEnough = false;
  @Input() modalData: any;
  @Output() close = new EventEmitter<any>();
  scrollY: number = 0;
  startY: number = 0;

  items = [
    { label: 'Branding', value: 'branding' },
    { label: 'TVC', value: 'tvc' },
    { label: 'Social Media', value: 'socialMedia' },
    { label: 'Web', value: 'web' },
    { label: 'Campaigns', value: 'campaigns' },
    { label: 'Indoor', value: 'indoor' },
    { label: 'Outdoor', value: 'outdoor' },
    { label: 'Print', value: 'print' },
    { label: 'Events', value: 'events' },
    { label: 'Logo', value: 'logo' }
  ];

  constructor(private fb: FormBuilder,private renderer: Renderer2) {}

  ngOnInit(): void {

    const formControls: Partial<ContactFormControls> = this.items.reduce((acc, item) => {
      acc[item.value] = new FormControl(false); // Create FormControl for each checkbox
      return acc;
    }, {} as Partial<ContactFormControls>);
    this.disableBodyScroll();
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      contactPreference: ['email', Validators.required], // Default to 'email'
      email: [''],
      phone: [''],
      description: ['', Validators.required],
      ...formControls // Spread the dynamically created controls into the form
    });

    // Update validators based on contactPreference value change
    this.contactForm.get('contactPreference')?.valueChanges.subscribe((value) => {
      if (value === 'email') {
        this.contactForm.get('email')?.setValidators([Validators.required, Validators.email]);
        this.contactForm.get('phone')?.clearValidators();
      } else {
        this.contactForm.get('phone')?.setValidators([Validators.required, Validators.pattern('^\\+?[0-9]{10,15}$')]);
        this.contactForm.get('email')?.clearValidators();
      }
      this.contactForm.get('email')?.updateValueAndValidity();
      this.contactForm.get('phone')?.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.enableBodyScroll(); // Enable body scroll when the modal is destroyed
  }

  disableBodyScroll() {
    document.documentElement.style.overflow = 'hidden'; // Disable body scroll
  }

  enableBodyScroll() {
    document.documentElement.style.overflow = 'auto'; // Enable body scroll
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const scrollPosition = window.scrollY;
    // Set the scrolling condition for modal visibility
    this.scrolledEnough = scrollPosition > 150; // Adjust threshold as needed
  }

  onCheckboxChange(interest: string) {
    console.log(`${interest} checked:`, this.contactForm.get(interest)?.value);
  }

  @HostListener('wheel', ['$event'])
  preventWindowScroll(event: WheelEvent): void {
    this.scrollY = this.scrollY + event.deltaY; 
    this.scrolledEnough = this.scrollY  > 150;

  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent): void {
    this.startY = event.touches[0].clientY;
  }

    // Handle touch move (mobile)
    @HostListener('touchmove', ['$event'])
    onTouchMove(event: TouchEvent): void {
      const deltaY = this.startY - event.touches[0].clientY;
      this.scrollY += deltaY;
      this.scrolledEnough = this.scrollY > 150;
    }
  

  submitForm() {
    if (this.contactForm.valid) {
      console.log('Form Submitted:', this.contactForm.value);
      // Handle form submission logic
    } else {
      console.log('Form is invalid');
    }
  }

  closeModal() {
    this.modalOpen = false; // Hide the modal
    this.renderer.removeClass(document.body, 'no-scroll');
    this.close.emit(this.modalOpen); // Emit close event to parent
    this.reset(); // Reset form or any state if necessary
    this.enableBodyScroll();
  }

  reset() {
    // Reset form or any relevant state
    this.contactForm.reset();
  }

   // Optional: Close modal on ESC key press
   @HostListener('document:keydown', ['$event'])
   handleKeyboardEvent(event: KeyboardEvent) {
     if (event.key === 'Escape') {
       this.closeModal();
     }
   }

}
