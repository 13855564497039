<div class="our-project" #ourProject> 
    <div class="our-project-container">
        <div class="project-titles">
            <div class="project-titles-font">Our Projects</div>
            <div class="button-container"> 
                <a class="button-projects" (click)="allprojects()">
                    See all Projects
                </a>
            </div>
        </div>
        <div #sliderContainer class="slider-container" >
            <div
              class="slide"
              *ngFor="let slide of slides; let i = index"
              
              [ngClass]="{
                'active': activeIndex === i,
                'prev': activeIndex === i + 1 || (activeIndex === 0 && i === slides.length - 1),
                'next': activeIndex === i - 1 || (activeIndex === slides.length - 1 && i === 0),
                'pre-prev': activeIndex === i + 2 || (activeIndex <= 1 && i === slides.length - (2 - activeIndex)),
                'next-next': activeIndex === i - 2 || (activeIndex >= slides.length - 2 && i === (activeIndex - slides.length + 2)),
                'pre-pre-prev': (activeIndex === (i + 3) % slides.length),
                'next-next-next': (activeIndex === (i - 3 + slides.length) % slides.length)
              }"
            >
              <img draggable="false" [src]="slide.image" alt="{{ slide.title }}" (click)="onSlideClick(slide.title)" />
              <p class="slide-title"
              [ngClass]="{
                'active': activeIndex === i,
                'prev': activeIndex === i + 1 || (activeIndex === 0 && i === slides.length - 1),
                'next': activeIndex === i - 1 || (activeIndex === slides.length - 1 && i === 0),
                'pre-prev': activeIndex === i + 2 || (activeIndex <= 1 && i === slides.length - (2 - activeIndex)),
                'next-next': activeIndex === i - 2 || (activeIndex >= slides.length - 2 && i === (activeIndex - slides.length + 2)),
                'pre-pre-prev': (activeIndex === (i + 3) % slides.length),
                'next-next-next': (activeIndex === (i - 3 + slides.length) % slides.length)
              }"
              (click)="onSlideClick(slide.title)" 
              >{{ slide.title }}</p>
            </div>
      </div>

          <div class="controls">
            <div class="left-control">
              <a class="left-c-font" (click)="prevSlide(); onUserInteraction()"> &#8592; </a>
            </div>
            <div class="right-control">
              <a  class="right-c-font"  (click)="nextSlide(); onUserInteraction()">&#8594; </a>
            </div>
          </div>

    </div>
</div>