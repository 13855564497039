import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject, Output, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { gsap } from "gsap/all";
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { forkJoin, Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppComponent } from '../app.component';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-spiralball',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './spiralball.component.html',
  styleUrl: './spiralball.component.css'
})
export class SpiralballComponent {

    // Spirale

    imageUrls: string[] = [];
    imagePaths: string[] = [];
    currentImageIndex = 1;
    @ViewChild('slideshowContainer') sliderShowContainer!: ElementRef;

    @ViewChildren('superpower') powerElements!: QueryList<ElementRef>;

    superpowers = [
      { number: '01', text: 'Clarity' },
      { number: '02', text: 'Speed' },
      { number: '03', text: 'Focus' },
      { number: '04', text: 'Expertise' }
    ];
    texts = [
      "Clarity is about being clear on your goals and objectives.",
      "Speed ensures we move quickly without sacrificing quality.",
      "Focus allows us to concentrate on what's truly important.",
      "Expertise in our field sets us apart and drives success."
    ];
  
    currentIndex = 0;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer,@Inject(PLATFORM_ID) private platformId: Object,private appComponent: AppComponent) {

  }

  openModal(): void {
    this.appComponent.openModal();
  }

  closeModal(): void {
    this.appComponent.closeModal(false);
  }


  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      for (let i = 1; i <= 67; i++) {
        this.imagePaths.push(`assets/spiralball/${i}.jpg`);
      }
      this.loadImagesInChunks();
      // this.startTextAnimation();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if( isPlatformBrowser(this.platformId)) {
      this.updateAnimations();
    }
  }

  startTextAnimation(): void {
      // GSAP animation with a timeline
      const timeline = gsap.timeline({ repeat: -1, repeatDelay: 2 });

      this.superpowers.forEach((_, index) => {
        timeline.to(this, {
          duration: 1,
          onUpdate: () => {
            this.currentIndex = index;
          },
          onComplete: () => {
            // Loop back to the first item if at the end
            if (index === this.superpowers.length - 1) {
              this.currentIndex = 0;
            }
          }
        });
      });
  }


  ngAfterViewInit() {
    if(this.sliderShowContainer && isPlatformBrowser(this.platformId)){
      this.slideShowAnimation();
      const scContainer = this.sliderShowContainer.nativeElement;
   
      scContainer.style.backgroundImage = `url(assets/spiralball/1.jpg)`;
      // this.updateAnimations(); 
    }
  
  }

  @Output() loaded = new EventEmitter();

  @HostListener('window:load')
  onWindowLoad() {
    this.loaded.emit('true');
  }


  slideShowAnimation(): void {
    const scContainer = this.sliderShowContainer.nativeElement;
  
    gsap.to(scContainer, {
      scrollTrigger: {
        trigger: scContainer,
        start: 'top top',
        end: '+=1000',
        scrub: 0.5,
        pin: true,
        anticipatePin: 1,
        onUpdate: (self) => {
          const progress = self.progress;
          const imageIndex = Math.max(
            1,
            Math.min(
              Math.floor(progress * (this.imageUrls.length - 1)),
              this.imageUrls.length - 1
            )
          );
  
          // Create a temporary image element to preload the image
          const nextImage = new Image();
          nextImage.src = this.imageUrls[imageIndex];
          nextImage.onload = () => {
            scContainer.style.backgroundImage = `url(${this.imageUrls[imageIndex]})`;
          };
        }
      }
    });
  }
  
  
  loadImagesInChunks(chunkSize: number = 10): void {
    const chunks: string[][] = [];
    for (let i = 0; i < this.imagePaths.length; i += chunkSize) {
      chunks.push(this.imagePaths.slice(i, i + chunkSize));
    }
  
    this.loadNextChunk(chunks);
  }
  
  loadNextChunk(chunks: string[][]): void {
    const currentChunk = chunks.shift();
  
    // Check if currentChunk is undefined
    if (!currentChunk || currentChunk.length === 0) {
      console.log('All chunks loaded.');
      return;
    }
  
    const imageObservables = currentChunk.map(path =>
      this.getImageAsBlob(path).pipe(
        switchMap(blob =>
          from(
            new Promise<string>((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result as string);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
          )
        )
      )
    );
  
    forkJoin(imageObservables).subscribe(
      (dataUrls: string[]) => {
        this.imageUrls.push(...dataUrls);
        console.log('Chunk loaded successfully');
        this.loadNextChunk(chunks); // Recursively load the next chunk
      },
      error => {
        console.error('Error loading chunk:', error);
      }
    );
  }
  
  
  getImageAsBlob(path: string): Observable<Blob> {
    return this.http.get(path, { responseType: 'blob' });
  }

  updateAnimations() {
    const elements = this.powerElements.toArray();

    // Reset all elements' positions and opacities
    gsap.set(elements, { opacity: 0, y: 50 });

    // Calculate the total height to trigger the scroll event
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;

    // Check which item should be active based on scroll position
    const scrollThreshold = windowHeight * 0.5; // Change threshold as needed
    const newIndex = Math.floor(scrollTop / scrollThreshold) % this.superpowers.length;

    if (this.currentIndex !== newIndex) {
      this.currentIndex = newIndex; // Update the active index
    }

    // Animate based on the current index
    elements.forEach((el, index) => {
      const position = index - this.currentIndex;

      if (position === 0) {
        // Active element
        gsap.to(el.nativeElement, { opacity: 1, y: 0, duration: 0.5 });
      } else if (position === 1) {
        // Next element
        gsap.to(el.nativeElement, { opacity: 0.6, y: 0, duration: 0.5 });
      } else if (position === 2) {
        // Next-next element
        gsap.to(el.nativeElement, { opacity: 0.3, y: 0, duration: 0.5 });
      } else if (position === 3) {
        // Next-next-next element
        gsap.to(el.nativeElement, { opacity: 0.1, y: 0, duration: 0.5 });
      } else {
        // Previous and beyond (hidden)
        gsap.to(el.nativeElement, { opacity: 0, y: 50, duration: 0.5 });
      }
    });

    // Adjust positions of the remaining elements when the active element is hidden
    elements.forEach((el, index) => {
      const position = index - this.currentIndex;
      
      if (position < 0) {
        // Move the previous elements up (for negative positions)
        gsap.to(el.nativeElement, { y: -50, duration: 0.5 });
      } else if (position > 0) {
        // Keep next elements in place (optional)
        gsap.to(el.nativeElement, { y: 0, duration: 0.5 });
      }
    });
  }
}
