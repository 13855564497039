<div class="our-lovely-client animate-left ">
    <div class="lovely-title">
        Our lovely <br>
Clients&Partners
    </div>

    <div class="lovely-icons">
        <div class="row">
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-01.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-02.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-03.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-04.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-09.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-11.png"> 
            </div>
        </div>
        <div class="row">
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-05.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-06.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-07.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-08.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-10.png"> 
            </div>
            <div class="w25">
                <img class="img-size" src="../../assets/logos/logos-12.png"> 
            </div>
        </div>
    </div>

    <div class="lovely-video">
        <video autoplay muted loop webkit-playsinline playsinline #lovelyVideo id="lovely-video">
            <source src="../../assets/video/color.mp4" type="video/mp4">
        </video>
        <div class="be-on">
            <div class="text-before-change">
                Let's make <br>something <span class="changable-text" [@textAnimation]="state">{{ texts[currentTextIndex] }}</span>
            </div>
            <div class="kick-a-project"> 
                Kick start a project with us today
            </div>
            <a class="discous-project" (click)="openModal()">
                DISCUSS YOUR PROJECT
            </a>
        </div>
    </div>
    
</div>