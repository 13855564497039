import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, RouterLinkActive,CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {

  constructor(private appComponent: AppComponent) {

  }


  openModal(): void {
    this.appComponent.openModal();
  }

  // Close modal and restore body scroll
  closeModal(): void {
    this.appComponent.closeModal(false);
  }

}
