import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lovely-client',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './lovely-client.component.html',
  styleUrl: './lovely-client.component.css',
  animations: [
    trigger('textAnimation', [
      state('hidden', style({
        opacity: 0,
        transform: 'translateY(20px)'
      })),
      state('visible', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('hidden => visible', [
        animate('1s ease-in-out')
      ]),
    ])
  ]
})
export class LovelyClientComponent {
  texts = ['exponential', 'strategic', 'engaging', 'outstanding'];
  currentTextIndex = 0;
  state = 'hidden';
  @ViewChild('lovelyVideo') video!: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private appComponent: AppComponent) {

  }

  ngOnInit() {
    // this.animateText();
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId))  {
      this.video.nativeElement.muted = true;
      if (this.video?.nativeElement?.play)  {
        this.video.nativeElement.play();
      }
      this.animateText();
    }
    
  }

  animateText() {
    setInterval(() => {
      this.state = 'hidden';
      setTimeout(() => {
        this.currentTextIndex = (this.currentTextIndex + 1) % this.texts.length;
        this.state = 'visible';
      }, 500);
    }, 3000);
  }

  openModal(): void {
    this.appComponent.openModal();
  }

  // Close modal and restore body scroll
  closeModal(): void {
    this.appComponent.closeModal(false);
  }

  @Output() loaded = new EventEmitter();

  @HostListener('window:load')
  onWindowLoad() {
    this.loaded.emit('true');
  }

}
